import { useRef, useState } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';

import { DataTableFilterMeta } from 'primereact/datatable';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { FileUpload } from 'primereact/fileupload';

import {
    exportNoticeItems,
    importNoticeItems,
    listNoticeItemsByProject,
} from 'services/nomia/noticeitem';

import NoticeItemsDataTable, {
    expectedNoticeItemsFilterValues,
} from '../../components/NoticeItemsDataTable';
import { useProjectQuery } from 'pages/Main/subpages/Projects/subpages/ListProjects/subpages';
import { useToast } from 'hooks/useToast';
import { useAuth } from 'hooks/useAuth';

import { ProgressBar } from 'primereact/progressbar';

const ListNoticeItems = () => {
    const { permissions } = useAuth();

    const fileUploadRef = useRef<FileUpload>(null);
    const toast = useToast();
    const projectQuery = useProjectQuery();

    const [isUploadFileModalVisible, setIsUploadFileModalVisible] =
        useState(false);
    const [selectedFile, setSelectedFile] = useState<File | null>(null);

    const params = useParams<{
        projectOid: string;
    }>() as { projectOid: string };

    const [pageOptions, setPageOptions] = useState<{
        page: number;
        rows: number;
    }>({ page: 1, rows: 50 });

    const [filters, setFilters] = useState<DataTableFilterMeta>(
        expectedNoticeItemsFilterValues
    );

    const [downloadProgress, setDownloadProgress] = useState(0.0);

    const {
        data: noticeItemsData,
        error: noticeItemsError,
        status: noticeItemsStatus,
        refetch: refetchNoticeItems,
    } = useQuery<Ether.Nomia.INoticeItem[], Error>(
        ['list-noticeitems', params.projectOid, filters, pageOptions],
        async ({ signal }): Promise<Ether.Nomia.INoticeItem[]> =>
            listNoticeItemsByProject(params.projectOid, {
                signal,
                filters,
                limit: pageOptions.rows,
                offset: (pageOptions.page - 1) * pageOptions.rows,
            })
    );

    const exportMutation = useMutation(async (): Promise<void> => {
        return exportNoticeItems(params.projectOid, {
            filters,
            onCountUpdate: (count, total) => {
                if (count > total) setDownloadProgress(100);
                else
                    setDownloadProgress(
                        Math.round((count / total) * 10000) / 100
                    );
            },
        });
    });

    const importMutation = useMutation<
        void,
        [number, string][] | Error,
        File,
        unknown
    >(
        async (file: File) => {
            const { created, updated, errors } = (
                await importNoticeItems(params.projectOid, file)
            )[0];
            if (created || updated) {
                if (errors.length > 0) {
                    toast?.show({
                        severity: 'warn',
                        summary: `Created ${created}, updated ${updated}, ${errors.length} error(s)`,
                        life: 10000,
                    });
                    throw errors;
                } else {
                    toast?.show({
                        severity: 'success',
                        summary: `Created ${created}, updated ${updated}`,
                        life: 10000,
                    });
                    fileUploadRef.current?.clear();
                    setIsUploadFileModalVisible(false);
                }
            } else {
                toast?.show({
                    severity: 'error',
                    summary: 'Failed to import items',
                    life: 10000,
                });
                throw errors;
            }
        },
        {
            onSettled: () => refetchNoticeItems(),
            onError: (err) => {
                if (err instanceof Error) {
                    console.error(err);
                    toast?.show({
                        severity: 'error',
                        summary: 'Failed to import items',
                        detail: err.toString(),
                        life: 30000,
                    });
                }
            },
        }
    );

    return (
        <div>
            <Dialog
                visible={isUploadFileModalVisible}
                onHide={() => {
                    fileUploadRef.current?.clear();
                    setIsUploadFileModalVisible(false);
                    importMutation.reset();
                }}
                header='Import Items'
            >
                <span>
                    Required fields: <b>value</b>
                </span>
                <br />
                <span>
                    Optional fields: <b>referer, title, imdb, meta</b>
                </span>
                <br />
                <div style={{ display: 'flex', gap: '8px' }}>
                    <FileUpload
                        ref={fileUploadRef}
                        accept='.csv'
                        mode='basic'
                        customUpload
                        chooseOptions={{
                            className: 'p-button-outlined',
                        }}
                        onSelect={(e) => {
                            setSelectedFile(
                                e.files.length > 0 ? e.files[0] : null
                            );
                        }}
                        disabled={importMutation.isLoading}
                        onClear={() => {
                            setSelectedFile(null);
                        }}
                    />
                    <Button
                        icon='pi pi-times'
                        className='p-button-outlined'
                        disabled={!selectedFile || importMutation.isLoading}
                        onClick={() => {
                            fileUploadRef.current?.clear();
                        }}
                    />
                    <Button
                        label='Upload'
                        icon='pi pi-upload'
                        className='p-button-outlined'
                        disabled={!selectedFile}
                        loading={importMutation.isLoading}
                        onClick={() => {
                            if (selectedFile)
                                importMutation.mutate(selectedFile);
                        }}
                    />
                </div>

                {importMutation.error &&
                    !(importMutation.error instanceof Error) && (
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                marginTop: '8px',
                                color: 'var(--darkish-red)',
                                maxHeight: '30vh',
                                overflowY: 'auto',
                            }}
                        >
                            <b style={{ marginBottom: '8px' }}>Errors</b>
                            {importMutation.error.map((err) => (
                                <span key={err[0] + err[1]}>
                                    Line {err[0]}: {err[1]}
                                </span>
                            ))}
                        </div>
                    )}
            </Dialog>
            <h2>{projectQuery?.data?.name} - Items</h2>
            <div style={{ display: 'flex', gap: '8px' }}>
                {permissions?.exportNotices && (
                    <Button
                        className='p-button-outlined'
                        icon='pi pi-download'
                        label='Export Items'
                        onClick={() => exportMutation.mutate()}
                        loading={exportMutation.isLoading}
                    />
                )}
                {permissions?.importNoticeItems && (
                    <Button
                        className='p-button-outlined'
                        icon='pi pi-upload'
                        label='Import Items'
                        onClick={() => setIsUploadFileModalVisible(true)}
                    />
                )}
            </div>
            {exportMutation.isLoading && (
                <ProgressBar
                    value={downloadProgress}
                    style={{ marginTop: '8px', marginBottom: '8px' }}
                />
            )}
            <NoticeItemsDataTable
                data={noticeItemsData}
                error={noticeItemsError}
                status={noticeItemsStatus}
                pageOptions={pageOptions}
                setPageOptions={setPageOptions}
                filters={filters}
                setFilters={setFilters}
                onDataUpdateNeeded={refetchNoticeItems}
            />
        </div>
    );
};

export default ListNoticeItems;
