import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';

import './index.css';

import './primereact-theme-ether.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';

const container = document.getElementById('root') as Element;

const root = ReactDOM.createRoot(container);

root.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
);
