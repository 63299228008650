import { Button } from 'primereact/button';
import { FallbackProps } from 'react-error-boundary';
import { useNavigate } from 'react-router-dom';

const ErrorFallback: React.FC<FallbackProps> = ({
    error,
}) => {
    const navigate = useNavigate();

    return (
        <div>
            <h3>An error has occurred</h3>
            <p>{error.message}</p>
            <Button label='Go back' onClick={() => navigate(-1)} />
        </div>
    );
};

export default ErrorFallback;
