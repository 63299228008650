import { createContext, useContext } from 'react';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { useErrorBoundary } from 'react-error-boundary';
import { Outlet, useParams } from 'react-router-dom';
import { getOneNotice } from 'services/nomia/notice';

const NoticeContext = createContext<UseQueryResult<
    Ether.Nomia.INotice,
    Error
> | null>(null);

const NoticeSubpages = () => {
    const errorBoundary = useErrorBoundary();

    const params = useParams<{
        noticeOid: string;
        projectOid: string;
    }>() as { noticeOid: string; projectOid: string };

    const noticeQuery = useQuery<Ether.Nomia.INotice, Error>(
        ['get-one-notice', params.noticeOid],
        async ({ signal }): Promise<Ether.Nomia.INotice> => {
            return getOneNotice(params.noticeOid, params.projectOid, {
                signal,
            });
        },
        {
            onSuccess: (data) => {
                if (data === null) errorBoundary.showBoundary(new Error('Notice not found'));
            },
            onError: (err) => errorBoundary.showBoundary(err),
        }
    );

    return noticeQuery.status === 'loading' ? (
        <h2>Loading notice info...</h2>
    ) : (
        <NoticeContext.Provider value={noticeQuery}>
            <Outlet />
        </NoticeContext.Provider>
    );
};

export const useNoticeQuery = () => {
    const context = useContext(NoticeContext);
    return context;
};

export default NoticeSubpages;
