import { FilterMatchMode } from 'primereact/api';
import {
    DataTableFilterMetaData,
    DataTableSortMeta,
} from 'primereact/datatable';
import { stringify } from 'csv-stringify/browser/esm/sync';
import downloadFromUrl from 'utils/downloadFromUrl';
import api from 'services/api';

export const handleFilterMatchMode = (
    key: string,
    value: string,
    mode: DataTableFilterMetaData['matchMode']
) => {
    if (value == null) return {};
    switch (mode) {
        case FilterMatchMode.STARTS_WITH:
            return { [key + '|regex']: '^' + value };
        case FilterMatchMode.CONTAINS:
            return { [key + '|regex']: value };
        case FilterMatchMode.NOT_CONTAINS:
            return { [key + '|regex']: `^(?!.*${value}).*$` };
        case FilterMatchMode.ENDS_WITH:
            return { [key + '|regex']: value + '$' };
        case FilterMatchMode.EQUALS:
            return { [key]: value };
        case FilterMatchMode.NOT_EQUALS:
            return { [key + '|ne']: value };
        case FilterMatchMode.LESS_THAN:
            return { [key + '|lt']: value };
        case FilterMatchMode.LESS_THAN_OR_EQUAL_TO:
            return { [key + '|lte']: value };
        case FilterMatchMode.GREATER_THAN:
            return { [key + '|gt']: value };
        case FilterMatchMode.GREATER_THAN_OR_EQUAL_TO:
            return { [key + '|gte']: value };
        case FilterMatchMode.IN:
            return { [key + '|in']: value };

        // case FilterMatchMode.GREATER_THAN_OR_EQUAL_TO: {
        //     const date = item[1].value as Date;
        //     apiFilters[item[0] + '|range'] = getDateRange(date);
        //     break;
        // }
        case FilterMatchMode.DATE_IS: {
            const tomorrow = new Date(value);
            tomorrow.setDate(tomorrow.getDate() + 1);
            return {
                [key + '|range']: `${value},${tomorrow.toISOString()}`,
            };
        }
        case FilterMatchMode.DATE_IS_NOT: {
            const tomorrow = new Date(value);
            tomorrow.setDate(tomorrow.getDate() + 1);
            return {
                [key + '|not,range']: `${value},${tomorrow.toISOString()}`,
            };
        }
        case FilterMatchMode.DATE_AFTER: {
            const tomorrow = new Date(value);
            tomorrow.setDate(tomorrow.getDate() + 1);
            return { [key + '|gte']: tomorrow.toISOString() };
        }
        case FilterMatchMode.DATE_BEFORE: {
            return { [key + '|lt']: value };
        }
        // case 'dateBefore': {
        //     const date = item[1].value as Date;
        //     apiFilters[item[0] + '|lt'] = `${getDateISO(date)}`;
        //     break;
        // }
        // case 'dateAfter': {
        //     const date = item[1].value as Date;
        //     apiFilters[item[0] + '|gt'] = `${getDateISO(date)}T23:59:59.999999`;
        //     break;
        // }
        default:
            throw new Error('match mode not implemented');
    }
};

interface GenericEtherItem {
    _id: string;
    [key: string]: any;
}

export async function exportAsCsv(options: {
    filename: string;
    filters: { [key: string]: string | number };
    csvHeaders: (
        | string
        | {
              field: string;
              name?: string;
              parseFunction?(value: any): string;
          }
    )[];
    fetchFn(filters: {
        [key: string]: string | number;
    }): Promise<GenericEtherItem[]>;
    crossFetchItem?: (item: GenericEtherItem) => Promise<GenericEtherItem>;
}) {
    const LIMIT = 500;

    const csvData: any[] = [];

    const { filename, filters, fetchFn, csvHeaders, crossFetchItem } = options;
    filters['order'] = '_id';
    filters['limit'] = LIMIT;

    let continueRequests = true;
    let requestsMade = 0;

    const finalCsvHeaders: string[] = csvHeaders.map((header) => {
        if (typeof header !== 'string') return header.name ?? header.field;
        return header;
    });
    while (continueRequests) {
        requestsMade += 1;
        if (requestsMade > 3000) throw new Error('exceeded amount of requests');

        const fetchItems = async () => {
            const items = await fetchFn(filters);
            if (!crossFetchItem) return items;
            const promises = items.map((i) => crossFetchItem(i));
            return await Promise.all(promises);
        };

        const items = await fetchItems();

        if (items.length > 0) {
            if (items.length < LIMIT) continueRequests = false;

            items.forEach((item) => {
                const itemData: any[] = [];
                csvHeaders.forEach((field) => {
                    if (typeof field === 'string') {
                        let value: any = item;
                        const splittedFields = field.split('.');
                        splittedFields.every((subField) => {
                            if (value[subField] instanceof Date) {
                                value = value[subField].toISOString();
                            } else value = value[subField];
                            if (value == null) return false;
                            return true;
                        });
                        itemData.push(value);
                    } else {
                        let value: any = item;
                        const splittedFields = field.field.split('.');
                        // parse value until its null or reached end
                        splittedFields.every((subField) => {
                            value = value[subField];
                            if (value == null) return false;
                            return true;
                        });
                        itemData.push(
                            field.parseFunction
                                ? field.parseFunction(value)
                                : value instanceof Date
                                ? value.toISOString()
                                : value?.toString()
                        );
                    }
                });
                csvData.push(itemData);
            });

            filters['_id|gt'] = items[items.length - 1]._id;
        } else {
            continueRequests = false;
        }
    }

    const output = stringify(csvData, {
        header: true,
        columns: finalCsvHeaders,
    });
    const blob = new Blob([output]);
    const fileDownloadUrl = URL.createObjectURL(blob);
    downloadFromUrl(fileDownloadUrl, filename, '.csv');
    URL.revokeObjectURL(fileDownloadUrl);
}

export async function exportAsCsvNoHeaders(options: {
    filename: string;
    filters: { [key: string]: string | number };
    fetchFn(filters: {
        [key: string]: string | number;
    }): Promise<GenericEtherItem[]>;
    crossFetchItem?: (item: GenericEtherItem) => Promise<GenericEtherItem>;
}) {
    const LIMIT = 500;

    const csvData: any[] = [];

    const { filename, filters, fetchFn, crossFetchItem } = options;
    filters['order'] = '_id';
    filters['limit'] = LIMIT;

    let continueRequests = true;
    let requestsMade = 0;

    while (continueRequests) {
        requestsMade += 1;
        if (requestsMade > 3000) throw new Error('exceeded amount of requests');

        const fetchItems = async () => {
            const items = await fetchFn(filters);
            if (!crossFetchItem) return items;
            const promises = items.map((i) => crossFetchItem(i));
            return await Promise.all(promises);
        };

        const items = await fetchItems();

        if (items.length > 0) {
            if (items.length < LIMIT) continueRequests = false;

            items.forEach((item) => csvData.push(item));

            filters['_id|gt'] = items[items.length - 1]._id;
        } else {
            continueRequests = false;
        }
    }

    const output = stringify(csvData, {
        header: true,
    });
    const blob = new Blob([output]);
    const fileDownloadUrl = URL.createObjectURL(blob);
    downloadFromUrl(fileDownloadUrl, filename, '.csv');
    URL.revokeObjectURL(fileDownloadUrl);
}

export const baseExportCsv = (
    endpoint: string,
    options?: {
        filename?: string;
        signal?: AbortSignal;
        filters?: { [key: string]: string | number | boolean };
        sort?: DataTableSortMeta;
        onCountUpdate?(count: number, total: number): void;
    }
) => {
    const apiUrl = window.API_URL;
    return new Promise<void>((resolve, reject) => {
        let { filters, onCountUpdate, filename } = options ?? {};
        if (!filters) filters = {};

        let finalOrder: string | null = null;
        if (options?.sort && options.sort.field) {
            const { order, field } = options.sort;
            if (order === 1) {
                finalOrder = field;
            } else if (order === -1) {
                finalOrder = '-' + field;
            }
        }
        if (finalOrder) filters['order'] = finalOrder;

        const headers: { [key: string]: string } = {};
        Object.entries(api.defaults.headers.common).forEach(([key, value]) => {
            headers[key] = value as string;
        });

        let queryString: string[] = [];
        filters['limit'] = 0;
        filters['return_count'] = 'true';
        Object.entries(filters).forEach(([key, value]) => {
            if (value == null) return;
            queryString.push(`${key}=${value}`);
        });

        const textDecoder = new TextDecoder();
        const textEncoder = new TextEncoder();
        let isFirstStream = true;
        let total: number | null = null;
        let sum = 0;
        let lastTime = Date.now();

        const updateCount = (count: number, total: number) => {
            lastTime = Date.now();
            if (onCountUpdate) onCountUpdate(count, total);
        };

        updateCount(0, 1);

        if (!endpoint.startsWith('/')) endpoint = '/' + endpoint;
        fetch(`${apiUrl}${endpoint}?${queryString.join('&')}`, {
            method: 'get',
            headers: headers,
            signal: options?.signal,
        })
            .then((response) => {
                if (!response.body) {
                    reject(new Error('failed to obtain stream'));
                    return;
                }
                const reader = response.body.getReader();
                return new ReadableStream({
                    start(controller) {
                        return pump();
                        function pump(): any {
                            return reader.read().then(({ done, value }) => {
                                const text = textDecoder.decode(value);
                                const rows = text.split('\n');
                                sum += rows.length;
                                if (rows[rows.length - 1] === '') sum -= 1;
                                if (isFirstStream) {
                                    total = Number(rows.splice(0, 1));
                                    sum -= 2;
                                    isFirstStream = false;
                                    value = textEncoder.encode(rows.join('\n'));
                                }
                                if (
                                    total != null &&
                                    Date.now() - lastTime > 1000
                                )
                                    updateCount(sum, total);
                                if (done) {
                                    controller.close();
                                    return;
                                }
                                controller.enqueue(value);
                                return pump();
                            });
                        }
                    },
                });
            })
            .then((stream) => new Response(stream))
            .then((response) => response.text())
            .then((data) => {
                // const csvData = data.split(/_(.*)/s)[1];
                const blob = new Blob([data], { type: 'text/csv' });
                const fileDownloadUrl = window.URL.createObjectURL(blob);
                downloadFromUrl(
                    fileDownloadUrl,
                    filename ?? 'unnamed_nomia_export',
                    '.csv'
                );
                resolve();
            })
            .catch((err) => reject(err));
    });
};

export const defaultPermissions: NomiaApp.Permissions = {
    listProject: false,
    countProject: false,
    queryProject: false,
    listNoticeItem: false,
    countNoticeItem: false,
    queryNoticeItem: false,
    listNotice: false,
    countNotice: false,
    queryNotice: false,
    exportNotices: false,
    getTitle: false,
    getSources: false,
    importNoticeItems: false,
    registerNotice: false,
};

export const getUserPermissions = (
    permissions: Ether.ApiPermissions,
    is_superadmin: boolean
): NomiaApp.Permissions => {
    let userPermissions = defaultPermissions;

    if (is_superadmin) {
        const userPermissionsKeys = Object.keys(userPermissions) as Array<
            keyof NomiaApp.Permissions
        >;

        userPermissionsKeys
            .map((permissionKey) => ({
                permissionKey,
                permissionValue: userPermissions[permissionKey],
            }))
            .filter(({ permissionKey, permissionValue }) => !permissionValue)
            .map(
                ({ permissionKey }) => (userPermissions[permissionKey] = true)
            );
    } else {
        const permissionsMap: {
            [key in Ether.MeInfo['permissions'][0]]?: (keyof NomiaApp.Permissions)[];
        } = {
            'list-project': ['listProject'],
            'count-project': ['countProject'],
            'query-project': ['queryProject'],
            'list-noticeitem': ['listNoticeItem'],
            'count-noticeitem': ['countNoticeItem'],
            'query-noticeitem': ['queryNoticeItem'],
            'list-notice': ['listNotice'],
            'count-notice': ['countNotice'],
            'query-notice': ['queryNotice'],
            'export-notices': ['exportNotices'],
            'get-titles': ['getTitle'],
            'get-sources': ['getSources'],
            'import-noticeitems': ['importNoticeItems'],
            'register-notice': ['registerNotice'],
        };

        permissions?.forEach((perm) => {
            const keys = permissionsMap[perm];

            if (!keys) return;

            keys.forEach((key) => {
                userPermissions[key] = true;
            });
        });
    }

    Object.entries(userPermissions).forEach(([key, value]) => {
        if (value === null)
            userPermissions[key as keyof NomiaApp.Permissions] = false;
    });

    return userPermissions as NomiaApp.Permissions;
};
