import { formatDuration } from 'date-fns';

export const getReadableAverageTimeUntilDown = (time: number) => {
    const MINUTE = 60000;
    const HOUR = MINUTE * 60;
    const DAY = HOUR * 24;
    const MONTH = DAY * 30;

    const duration = {
        months: 0,
        days: 0,
        hours: 0,
    };

    let value = time;

    if (!value) return '-';

    if (value < HOUR) return 'Less than a hour';

    if (value >= MONTH) {
        duration.months = Math.floor(value / MONTH);
        value = value % MONTH;
    }
    if (value >= DAY) {
        duration.days = Math.floor(value / DAY);
        value = value % DAY;
    }
    if (value >= HOUR) {
        duration.hours = Math.floor(value / HOUR);
        value = value % HOUR;
    }
    const res = formatDuration(duration);
    return res !== '' ? res : '-';
};
