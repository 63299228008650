import {
    DataTableFilterMeta,
    DataTableFilterMetaData,
    DataTableOperatorFilterMetaData,
    DataTableSortMeta,
} from 'primereact/datatable';

import api from 'services/api';
import { baseExportCsv, handleFilterMatchMode } from 'services/utils';
import isValidOid from 'utils/isValidOid';

const apiUrl = window.API_URL;

const handleDataTableFilterMeta = (filters?: DataTableFilterMeta) => {
    if (!filters) return {};
    let paramFilters: { [key: string]: string | number } = {};
    if (filters['_id']) {
        const filterMeta = filters['_id'] as DataTableOperatorFilterMetaData;
        const oid = filterMeta.constraints[0].value;
        if (isValidOid(oid)) paramFilters['_id'] = oid;
    }
    if (filters['target.source']) {
        const filterMeta = filters[
            'target.source'
        ] as DataTableOperatorFilterMetaData;
        paramFilters = {
            ...paramFilters,
            ...handleFilterMatchMode(
                'target.source',
                filterMeta.constraints[0]?.value,
                filterMeta.constraints[0].matchMode
            ),
        };
    }
    if (filters['target.source_type']) {
        const filterMeta = filters[
            'target.source_type'
        ] as DataTableOperatorFilterMetaData;
        paramFilters = {
            ...paramFilters,
            ...handleFilterMatchMode(
                'target.source_type',
                filterMeta.constraints[0]?.value,
                filterMeta.constraints[0].matchMode
            ),
        };
    }
    if (filters['status']) {
        paramFilters['status'] = (
            filters['status'] as DataTableFilterMetaData
        )?.value;
    }
    if (filters['sent_at']) {
        const filterMeta = filters[
            'sent_at'
        ] as DataTableOperatorFilterMetaData;
        paramFilters = {
            ...paramFilters,
            ...handleFilterMatchMode(
                'sent_at',
                filterMeta.constraints[0]?.value,
                filterMeta.constraints[0].matchMode
            ),
        };
    }
    if (filters['counter_notice.received_at']) {
        const filterMeta = filters[
            'counter_notice.received_at'
        ] as DataTableOperatorFilterMetaData;
        paramFilters = {
            ...paramFilters,
            ...handleFilterMatchMode(
                'counter_notice.received_at',
                filterMeta.constraints[0]?.value,
                filterMeta.constraints[0].matchMode
            ),
        };
    }
    if (filters['counter_notice.last_sent_at']) {
        const filterMeta = filters[
            'counter_notice.last_sent_at'
        ] as DataTableOperatorFilterMetaData;
        paramFilters = {
            ...paramFilters,
            ...handleFilterMatchMode(
                'counter_notice.last_sent_at',
                filterMeta.constraints[0]?.value,
                filterMeta.constraints[0].matchMode
            ),
        };
    }
    if (filters['counter_notice.status']) {
        const filterMeta = filters[
            'counter_notice.status'
        ] as DataTableOperatorFilterMetaData;

        if (filterMeta.constraints[0].value === '@any') {
            paramFilters = {
                ...paramFilters,
                'counter_notice.status|isnull': 'false',
            };
        } else {
            if (Array.isArray(filterMeta.constraints[0]?.value)) {
                filterMeta.constraints[0].value =
                    filterMeta.constraints[0].value.join(',');
            }

            paramFilters = {
                ...paramFilters,
                ...handleFilterMatchMode(
                    'counter_notice.status',
                    filterMeta.constraints[0]?.value,
                    filterMeta.constraints[0].matchMode
                ),
            };
        }
    }
    return paramFilters;
};

export const listNoticesByProject = (
    projectId: string,
    options?: {
        signal?: AbortSignal;
        limit?: number;
        offset?: number;
        filters?: DataTableFilterMeta;
        sortField?: string | null;
        sortOrder?: DataTableSortMeta['order'] | null;
    }
): Promise<Ether.Nomia.INotice[]> => {
    return new Promise<Ether.Nomia.INotice[]>((resolve, reject) => {
        const filters = handleDataTableFilterMeta(options?.filters);

        let order: string | null = null;
        if (options?.sortField && options.sortOrder) {
            if (options.sortOrder === 1) {
                order = options.sortField;
            } else if (options.sortOrder === -1) {
                order = '-' + options.sortField;
            }
        }
        if (order) filters['order'] = order;

        api.get<Ether.IApi<Ether.Nomia.INotice>>(`${apiUrl}/list-notice`, {
            signal: options?.signal,
            params: {
                project_oid: projectId,
                limit: options?.limit ?? 10,
                offset: options?.offset ?? 0,
                ...filters,
            },
        })
            .then((result) => {
                const notices = result.data.payload;
                resolve(notices);
            })
            .catch(reject);
    });
};

export const getOneNotice = (
    noticeId: string,
    projectId: string,
    options?: {
        signal?: AbortSignal;
    }
): Promise<Ether.Nomia.INotice> => {
    return new Promise<Ether.Nomia.INotice>((resolve, reject) => {
        api.get<Ether.IApi<Ether.Nomia.INotice>>(`${apiUrl}/list-notice`, {
            signal: options?.signal,
            params: {
                _id: noticeId,
                project_oid: projectId,
            },
        })
            .then((result) => {
                const notice = result.data.payload[0];
                resolve(notice ?? null);
            })
            .catch(reject);
    });
};

export const getManyNotices = (
    noticeIds: string[],
    projectId: string,
    options?: {
        signal?: AbortSignal;
    }
) => {
    return new Promise<Ether.Nomia.INotice[]>((resolve, reject) => {
        api.get<Ether.IApi<Ether.Nomia.INotice>>(`${apiUrl}/list-notice`, {
            signal: options?.signal,
            params: {
                '_id|in': noticeIds.join(','),
                project_oid: projectId,
            },
        })
            .then((result) => {
                const notices = result.data.payload;
                resolve(notices ?? []);
            })
            .catch(reject);
    });
};

export const finishNoticeJob = (
    noticeId: string,
    data: {
        sent_at: Date;
        sent_info: string;
    },
    options?: {
        signal?: AbortSignal;
    }
): Promise<Ether.Nomia.INotice> => {
    return new Promise<Ether.Nomia.INotice>((resolve, reject) => {
        api.post<Ether.IApi<Ether.Nomia.INotice>>(
            `${apiUrl}/finish-notice-job`,
            {
                notice_id: noticeId,
                status: 'sent',
                ...data,
            },
            {
                signal: options?.signal,
            }
        )
            .then((result) => {
                const notice = result.data.payload[0];
                resolve(notice);
            })
            .catch(reject);
    });
};

export const createManualNotice = (
    data: {
        project_oid: string;
        source: string;
        source_type: string;
        max_notices: number;
        title_slug?: string;
    },
    options?: {
        signal?: AbortSignal;
    }
) => {
    return new Promise<
        {
            notice_number: number;
            notice_id?: string;
            created: boolean;
            error?: string;
        }[]
    >((resolve, reject) => {
        api.post<
            Ether.IApi<{
                notice_number: number;
                notice_id?: string;
                created: boolean;
                error?: string;
            }>
        >(`${apiUrl}/create-manual-notice`, data, {
            signal: options?.signal,
        })
            .then((result) => {
                const data = result.data.payload;
                resolve(data);
            })
            .catch(reject);
    });
};

// export const exportNotices = async (
//     projectId: string,
//     options?: {
//         filters?: DataTableFilterMeta;
//     }
// ) => {
//     const fetchNotices = (filters: { [key: string]: string | number }) => {
//         return new Promise<Ether.Nomia.INotice[]>((resolve, reject) => {
//             api.get<Ether.IApi<Ether.Nomia.INotice>>(`${apiUrl}/list-notice`, {
//                 params: {
//                     project_oid: projectId,
//                     ...filters,
//                 },
//             })
//                 .then((result) => {
//                     const notices = result.data.payload;
//                     resolve(notices);
//                 })
//                 .catch(reject);
//         });
//     };

//     const csvHeaders: string[] = [
//         '_id',
//         'project_oid',
//         'v1_id',
//         'status',
//         'last_assigned_at',
//         'noticeitem_count',
//         'max_items',
//         'target.source',
//         'target.source_type',
//         'sent_at',
//         'sent_info',
//         'meta',
//         'created_at',
//         'updated_at',
//     ];

//     const filters = handleDataTableFilterMeta(options?.filters);

//     await exportAsCsv({
//         filename: 'nomiav2_' + projectId + '_notices',
//         fetchFn: fetchNotices,
//         filters,
//         csvHeaders,
//     });
// };

// export const exportCounterNotices = async (
//     projectId: string,
//     options?: {
//         filters?: DataTableFilterMeta;
//     }
// ) => {
//     const fetchNotices = (filters: { [key: string]: string | number }) => {
//         return new Promise<Ether.Nomia.INotice[]>((resolve, reject) => {
//             api.get<Ether.IApi<Ether.Nomia.INotice>>(`${apiUrl}/list-notice`, {
//                 params: {
//                     project_oid: projectId,
//                     ...filters,
//                 },
//             })
//                 .then((result) => {
//                     const notices = result.data.payload;
//                     resolve(notices);
//                 })
//                 .catch(reject);
//         });
//     };

//     const csvHeaders: (
//         | string
//         | {
//               field: string;
//               name?: string | undefined;
//               parseFunction?(value: any): string;
//           }
//     )[] = [
//         '_id',
//         'project_oid',
//         'v1_id',
//         'status',
//         'last_assigned_at',
//         'noticeitem_count',
//         'max_items',
//         'target.source',
//         'target.source_type',
//         'sent_at',
//         'sent_info',
//         'counter_notice.status',
//         'counter_notice.created_at',
//         'counter_notice.response.responded_at',
//         {
//             field: 'counter_notice.response.approved',
//             parseFunction: (data) =>
//                 data == null ? '' : data ? 'true' : 'false',
//         },
//         'counter_notice.response.answer',
//         'counter_notice.data.content',
//         'meta',
//         'created_at',
//         'updated_at',
//     ];

//     const filters = handleDataTableFilterMeta(options?.filters);

//     await exportAsCsv({
//         filename: 'nomiav2_' + projectId + '_counternotices',
//         fetchFn: fetchNotices,
//         filters,
//         csvHeaders,
//     });
// };

export const exportNotices = (
    projectId: string,
    op: 'notice' | 'counter_notice',
    options?: {
        signal?: AbortSignal;
        filters?: DataTableFilterMeta;
        sort?: DataTableSortMeta;
        onCountUpdate?(count: number, total: number): void;
    }
) => {
    const filters = handleDataTableFilterMeta(options?.filters);
    filters['project_oid'] = projectId;
    filters['op'] = op;
    const filename =
        'nomiav2_' +
        projectId +
        (op === 'notice' ? '_notices' : '_counternotices');
    return baseExportCsv('/export-notices', {
        ...options,
        filename,
        filters,
    });
};

export type CreateNoticeResult = {
    notice_oid: string;
    items?: {
        created: number;
        updated: number;
        errors: { index: number; error: string }[];
    };
};

export const createNotice = async (data: {
    projectId: string;
    source: string;
    sourceType: string;
    status: 'new' | 'queued';
    isTest: boolean;
    workerConfig: { [key: string]: any };
    noticeItems?: { [key: string]: any }[];
    upsertItems?: boolean;
}) => {
    return new Promise<CreateNoticeResult>((resolve, reject) => {
        api.post<Ether.IApi<CreateNoticeResult>>(
            `${apiUrl}/register-notice`,
            {
                payload: [
                    {
                        project_oid: data.projectId,
                        source: data.source,
                        source_type: data.sourceType,
                        status: data.status,
                        is_test: data.isTest,
                        worker_config: data.workerConfig,
                        noticeitems: data.noticeItems,
                    },
                ],
            },
            {
                params: {
                    upsert_items: data.upsertItems,
                },
            }
        )
            .then((result) => {
                resolve(result.data.payload[0]);
            })
            .catch(reject);
    });
};

export const queueCounterNotice = async (
    noticeId: string,
    approved: boolean,
    answer: string
) => {
    return new Promise<boolean>((resolve, reject) => {
        api.post<Ether.IApi<boolean>>(
            `${apiUrl}/queue-counter-notice/${noticeId}`,
            {
                approved,
                answer,
            }
        )
            .then((result) => {
                resolve(result.data.payload[0]);
            })
            .catch(reject);
    });
};

export const countNotice = <T>(options?: {
    signal?: AbortSignal;
    filters?: { [key: string]: any };
}) => {
    return new Promise<(Partial<T> & { count: number })[]>(
        (resolve, reject) => {
            api.get<{
                success: string;
                meta: { payload_count: number; [key: string]: unknown };
                payload: (Partial<T> & { count: number })[] | number;
            }>(`${apiUrl}/count-notice`, {
                signal: options?.signal,
                params: {
                    ...options?.filters,
                },
                timeout: 15 * 60 * 1000,
            })
                .then((result) => {
                    const data = result.data.payload;
                    if (typeof data === 'number') {
                        resolve([
                            {
                                count: data,
                            } as any,
                        ]);
                    } else {
                        resolve(data);
                    }
                })
                .catch(reject);
        }
    );
};
