import { useRef, useState } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useNavigate, useParams } from 'react-router-dom';
import Papa from 'papaparse';

import { Button } from 'primereact/button';
import { FileUpload } from 'primereact/fileupload';

import { getOneProject } from 'services/nomia/project';
import { createNotice, CreateNoticeResult } from 'services/nomia/notice';

import {
    Checkbox,
    Dropdown,
    InputText,
    InputTextArea,
} from 'components/ethercity-primereact';
import { useToast } from 'hooks/useToast';
import { ToastMessage } from 'primereact/toast';

const convertCsvToJson = async (
    file: File
): Promise<{ [key: string]: any }[]> => {
    return new Promise((resolve) => {
        const objectList: any[] = [];
        let headers: string[];
        let cursor: number = 0;

        Papa.parse(file, {
            step: (results) => {
                if (cursor === 0) {
                    headers = results.data as string[];
                    cursor = results.meta.cursor;
                    return;
                }
                if (cursor === results.meta.cursor) return;

                const zip = (rows: any[][]) =>
                    rows[0].map((_: any, c: any) => rows.map((row) => row[c]));

                cursor = results.meta.cursor;
                const item: any = {};
                zip([headers, results.data as any[]]).forEach(
                    ([key, value]) => {
                        if (value === '') item[key] = null;
                        item[key] = value;
                    }
                );
                objectList.push(item);
            },
            complete: () => resolve(objectList),
        });
    });
};

const CreateNotice = () => {
    const toast = useToast();
    const fileUploadRef = useRef<FileUpload>(null);

    const navigate = useNavigate();

    const params = useParams<{
        projectOid: string;
        noticeOid: string;
    }>();

    const [isErrored, setIsErrored] = useState(false);
    const [formData, setFormData] = useState<{
        source: string;
        sourceType: string;
        status: 'new' | 'queued';
        noticeItems: string;
        noticeItemsCsvFile: File | null;
        isTest: boolean;
        workerConfig: string;
        upsertItems: boolean;
    }>({
        source: '',
        sourceType: '',
        status: 'new',
        noticeItems: '',
        noticeItemsCsvFile: null,
        isTest: false,
        workerConfig: '',
        upsertItems: false,
    });

    const {
        data: projectData,
        error: projectError,
        status: projectStatus,
    } = useQuery<Ether.Nomia.IProject, Error>(
        ['get-one-project', params.projectOid],
        async ({ signal }): Promise<Ether.Nomia.IProject> => {
            if (!params.projectOid) throw Error('projectOid not defined');
            return getOneProject(params.projectOid, { signal });
        }
    );

    const createNoticeMutation = useMutation<CreateNoticeResult, Error>(
        async (): Promise<CreateNoticeResult> => {
            if (!projectData) throw new Error('data missing');

            let finalItems: {
                [key: string]: any;
            }[];

            if (formData.noticeItemsCsvFile) {
                finalItems = await convertCsvToJson(
                    formData.noticeItemsCsvFile
                );
            } else {
                finalItems = JSON.parse(formData.noticeItems);
            }

            return createNotice({
                projectId: projectData._id,
                source: formData.source,
                sourceType: formData.sourceType,
                isTest: formData.isTest,
                status: formData.status,
                workerConfig:
                    formData.workerConfig === ''
                        ? {}
                        : JSON.parse(formData.workerConfig),
                noticeItems: finalItems,
                upsertItems:
                    formData.status !== 'queued'
                        ? undefined
                        : formData.upsertItems,
            });
        },
        {
            onSuccess: (res) => {
                const toastData: ToastMessage = {
                    severity: 'success',
                    summary: 'Created notice.',
                    life: 10000,
                };

                if (res.items) {
                    const { items } = res;
                    if (items.created > 0) {
                        toastData.summary += ` ${items.created} items added.`;
                    }
                    if (items.updated > 0) {
                        toastData.summary += ` ${items.updated} items updated.`;
                    }
                    if (items.errors.length > 0) {
                        toastData.summary += ` ${items.errors.length} items errored.`;
                    }
                    if (items.errors.length > 0) {
                        toastData.life = 30000;
                        toastData.detail = (
                            <div>
                                {items.errors.slice(0, 4).map((item, index) =>
                                    index < 3 ? (
                                        <p key={index}>
                                            {formData.noticeItemsCsvFile
                                                ? `Row ${item.index + 2} `
                                                : `Item ${item.index + 1} `}
                                            - {item.error}
                                        </p>
                                    ) : (
                                        <p key={index}>
                                            And {items.errors.length - 3} more
                                            errors
                                        </p>
                                    )
                                )}
                            </div>
                        );
                    }

                    if (items.errors.length > 0) {
                        toastData.severity = 'warn';
                    }
                }

                toast?.show(toastData);
                navigate('..');
            },
            onError: (err) => {
                toast?.show({
                    severity: 'error',
                    summary: 'Failed to create notice',
                    detail: err.message,
                });
            },
        }
    );

    return (
        <div>
            {projectStatus === 'loading' ? (
                <h2>Loading project info...</h2>
            ) : projectError ? (
                <h2>Error getting project: {projectError.toString()}</h2>
            ) : !projectData ? (
                <h2>Project not found</h2>
            ) : (
                <>
                    <h2>{projectData.name} - Create notice</h2>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                            gap: '8px',
                        }}
                    >
                        <InputText
                            label='Source'
                            value={formData.source}
                            onChange={(e) =>
                                setFormData((old) => ({
                                    ...old,
                                    source: e.target.value,
                                }))
                            }
                        />
                        <InputText
                            label='Source type'
                            value={formData.sourceType}
                            onChange={(e) =>
                                setFormData((old) => ({
                                    ...old,
                                    sourceType: e.target.value,
                                }))
                            }
                        />
                        <Dropdown
                            label='Status'
                            value={formData.status}
                            onChange={(e) => {
                                const { value: status } = e.target;
                                if (status === 'new') {
                                    setFormData((old) => ({
                                        ...old,
                                        noticeItems: '',
                                        status: status,
                                    }));
                                } else {
                                    setFormData((old) => ({
                                        ...old,
                                        status: status,
                                    }));
                                }
                            }}
                            options={[
                                {
                                    value: 'new',
                                    label: 'New',
                                },
                                {
                                    value: 'queued',
                                    label: 'Queued',
                                },
                            ]}
                        />
                        {formData.status === 'queued' && (
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: '8px',
                                }}
                            >
                                <InputTextArea
                                    label='Items'
                                    value={formData.noticeItems}
                                    onChange={(e) =>
                                        setFormData((old) => ({
                                            ...old,
                                            noticeItems: e.target.value,
                                        }))
                                    }
                                    validations={[
                                        {
                                            validate: (value) => {
                                                try {
                                                    if (!value) return true;
                                                    const obj =
                                                        JSON.parse(value);
                                                    if (
                                                        !obj ||
                                                        !Array.isArray(obj)
                                                    )
                                                        return false;
                                                    return obj.every(
                                                        (a) =>
                                                            a.constructor ===
                                                            Object
                                                    );
                                                } catch {
                                                    return false;
                                                }
                                            },
                                            validationError:
                                                'Not an list of JSON-items',
                                        },
                                    ]}
                                    disabled={
                                        formData.noticeItemsCsvFile != null
                                    }
                                    validationCallback={(isValid) => {
                                        setIsErrored(!isValid);
                                    }}
                                />
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'flex-start',
                                        gap: '8px',
                                    }}
                                >
                                    <FileUpload
                                        ref={fileUploadRef}
                                        accept='.csv'
                                        mode='basic'
                                        customUpload
                                        chooseOptions={{
                                            className: 'p-button-outlined',
                                            label: 'Select CSV file',
                                        }}
                                        onSelect={(e) => {
                                            setFormData((old) => ({
                                                ...old,
                                                noticeItemsCsvFile: e.files[0],
                                            }));
                                        }}
                                        disabled={formData.noticeItems !== ''}
                                        onClear={() => {
                                            setFormData((old) => ({
                                                ...old,
                                                noticeItemsCsvFile: null,
                                            }));
                                        }}
                                    />
                                    {formData.noticeItemsCsvFile != null && (
                                        <Button
                                            label='Clear'
                                            icon='pi pi-times'
                                            className='p-button-outlined'
                                            onClick={() => {
                                                fileUploadRef.current?.clear();
                                            }}
                                        />
                                    )}
                                </div>
                            </div>
                        )}
                        {formData.status === 'queued' && (
                            <Checkbox
                                label='Upsert items?'
                                checked={formData.upsertItems}
                                onChange={(e) =>
                                    setFormData((old) => ({
                                        ...old,
                                        upsertItems: e.checked ?? false,
                                    }))
                                }
                            />
                        )}
                        <Checkbox
                            label='Is test?'
                            checked={formData.isTest}
                            onChange={(e) =>
                                setFormData((old) => ({
                                    ...old,
                                    isTest: e.checked ?? false,
                                }))
                            }
                        />
                        <InputTextArea
                            label='Worker config'
                            value={formData.workerConfig}
                            onChange={(e) =>
                                setFormData((old) => ({
                                    ...old,
                                    workerConfig: e.target.value,
                                }))
                            }
                            validations={[
                                {
                                    validate: (value) => {
                                        try {
                                            if (!value) return true;
                                            const obj = JSON.parse(value);

                                            return (
                                                !!obj &&
                                                obj.constructor === Object
                                            );
                                        } catch {
                                            return false;
                                        }
                                    },
                                    validationError: 'Not JSON-like',
                                },
                            ]}
                            validationCallback={(isValid) => {
                                setIsErrored(!isValid);
                            }}
                        />
                        <Button
                            className='p-button-outlined'
                            icon='pi pi-plus'
                            label='Create Notice'
                            onClick={() => createNoticeMutation.mutate()}
                            loading={createNoticeMutation.isLoading}
                            disabled={
                                formData.source === '' ||
                                formData.sourceType === '' ||
                                isErrored
                            }
                        />
                    </div>
                </>
            )}
        </div>
    );
};

export default CreateNotice;
