import './style.css';

export interface ILogo {
    label: string;
    size?: number;
    labelRatio?: number;
    enableHover?: boolean;
}

export const Logo: React.FC<ILogo> = ({
    label,
    size = 20,
    labelRatio = 1.7,
    enableHover,
}) => {
    const className = 'logo' + (enableHover ? ' logo-hover' : '');
    return (
        <div className={className}>
            <div className='t1' style={{ fontSize: size }}>
                &AElig;ther
            </div>
            <div className='t2' style={{ fontSize: (size * labelRatio) }}>
                {label}
            </div>
        </div>
    );
};
