import { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';

import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';

import { listProjects } from 'services/nomia/project';

import { useAuth } from 'hooks/useAuth';

import './style.css';
import { OidBadge, Paginator } from 'components/ethercity-primereact';

const ListProjects = () => {
    const { permissions } = useAuth();

    const navigate = useNavigate();
    const [pageOptions, setPageOptions] = useState<{
        page: number;
        rows: number;
    }>({ page: 1, rows: 50 });

    const { data, error, status } = useQuery<Ether.Nomia.IProject[], Error>(
        ['list-project', pageOptions],
        async ({ signal }): Promise<Ether.Nomia.IProject[]> => {
            return listProjects({
                signal,
                limit: pageOptions.rows,
                offset: (pageOptions.page - 1) * pageOptions.page,
            });
        }
    );

    const renderActionButtons = (rowData: Ether.Nomia.IProject) => {
        return (
            <div className='action-buttons-wrap'>
                {permissions?.listNotice && (
                    <>
                        <Button
                            className='p-button-outlined'
                            label='Notices'
                            onClick={() => navigate(`${rowData._id}/notices`)}
                        />
                        <Button
                            className='p-button-outlined'
                            label='Counter-Notices'
                            onClick={() =>
                                navigate(`${rowData._id}/counternotices`)
                            }
                        />
                    </>
                )}
                {permissions?.listNoticeItem && (
                    <Button
                        className='p-button-outlined'
                        label='Items'
                        onClick={() => navigate(`${rowData._id}/noticeitems`)}
                    />
                )}
                {permissions?.listProject && (
                    <Button
                        className='p-button-outlined'
                        icon='pi pi-chart-bar'
                        label='Metrics'
                        onClick={() => navigate(`${rowData._id}/metrics`)}
                    />
                )}
            </div>
        );
    };

    return (
        <div>
            <h2>Projects</h2>
            {error ? (
                error.message
            ) : (
                <div>
                    <Paginator
                        disableNext={!data || data.length < pageOptions.rows}
                        page={pageOptions.page}
                        rows={pageOptions.rows}
                        onPageChange={setPageOptions}
                    />
                    <DataTable
                        value={data}
                        loading={status === 'loading'}
                        emptyMessage='No projects available'
                        lazy={true}
                    >
                        <Column
                            field='_id'
                            header='OID'
                            body={(rowData) => <OidBadge value={rowData._id} />}
                        />
                        <Column field='name' header='Name' />
                        <Column header='Actions' body={renderActionButtons} />
                    </DataTable>
                </div>
            )}
        </div>
    );
};

export default ListProjects;
