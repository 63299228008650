const downloadFromUrl = (url : string, filename ?: string, extension ?: string) => {
    extension = extension ? extension : '';
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = url;
    a.download = (filename ? filename : 'unknown') + (extension);
    document.body.appendChild(a);
    a.click();
};

export default downloadFromUrl;