import { Outlet } from 'react-router-dom';

import Navbar from 'components/Page/Navbar';
import PageWrap from 'components/Page/PageWrap';
import ContentWrap from 'components/Page/ContentWrap';
import { Button } from 'primereact/button';
import { useAuth } from 'hooks/useAuth';
import { ToastProvider } from 'hooks/useToast';

const Main = () => {
    const { permissions, signOut } = useAuth();

    const navigations = [
        {
            label: 'Home',
            path: '/',
        },
    ];

    if (permissions?.listProject)
        navigations.push({
            label: 'Projects',
            path: '/projects',
        });

    return (
        <PageWrap>
            <Navbar
                navigations={navigations}
                endContent={
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Button label='Sign out' onClick={signOut} />
                    </div>
                }
            />
            <ContentWrap>
                <ToastProvider>
                    <Outlet />
                </ToastProvider>
            </ContentWrap>
        </PageWrap>
    );
};

export default Main;
