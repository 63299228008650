import { ColumnFilterElementTemplateOptions } from 'primereact/column';
import { InputText } from 'components/ethercity-primereact/components/input/InputText';
import isValidOid from '../../../utils/isValidOid';

const OidFilter = (options : ColumnFilterElementTemplateOptions) => {
    const isError = options.value && !isValidOid(options.value);

    return (
        <>
            <InputText
                style={{
                    minWidth: '160px',
                }}
                placeholder='Search by ID'
                value={options.value === null ? '' : options.value}
                onChange={(e) => options.filterCallback(e.target.value)}
                maxLength={24}
                error={isError && 'Invalid OID'}
            />
        </>
    );
};

export default OidFilter;
