import { Toast } from 'primereact/toast';
import {
    createContext,
    useCallback,
    useContext,
    useState,
} from 'react';

const ToastContext = createContext<Toast | null>(null);

const ToastProvider: React.FC<{
    children?: React.ReactNode;
}> = ({ children }) => {
    const [toast, setToast] = useState<Toast | null>(null);
    const onRefChange = useCallback((node: Toast) => {
        if (node == null || toast != null) return;
        setToast(node);
    }, [toast]);

    return (
        <>
            <Toast ref={onRefChange} onRemove={() => toast?.clear()} />
            <ToastContext.Provider value={toast}>
                {children}
            </ToastContext.Provider>
        </>
    );
};

const useToast = () => {
    const context = useContext(ToastContext);
    return context;
};

export { ToastProvider, useToast };
