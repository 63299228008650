import storageJSONSafeParse from 'utils/storageJSONSafeParse';

export const MAX_CACHE_TIME = 30 * 60 * 1000; // 30 minutes

enum STORAGE_KEYS {
    AUTH = 'nomia:auth',
    COUNT_FILTERS = 'nomia:count_filters',
    ASSIGNED_NOTICES = 'nomia:assigned_notices',
}

namespace CacheSavedTypes {
    export type Auth = {
        token: string | null;
        user: any;
    };
}

abstract class BaseCache<T> {
    key: string;
    constructor(key: string) {
        this.key = key;
        this.validate();
    }

    abstract validate(): void;
    protected abstract getAndHandleStorage(...args: any[]): T | null;

    get(...args: any[]): T | null {
        this.validate();
        return this.getAndHandleStorage(...args);
    }
    abstract save(...args: any[]): void;
    delete(): void {
        localStorage.removeItem(this.key);
    }
}

namespace CacheManager {
    export class Auth extends BaseCache<CacheSavedTypes.Auth> {
        constructor() {
            super(STORAGE_KEYS.AUTH);
        }
        validate() {
            const auth = storageJSONSafeParse<CacheSavedTypes.Auth>(this.key);
            if (!auth) return;
            if (Object.prototype.toString.call(auth) !== '[object Object]') {
                this.delete();
                return;
            }
        }
        getAndHandleStorage() {
            return storageJSONSafeParse<CacheSavedTypes.Auth>(this.key);
        }
        save(value: CacheSavedTypes.Auth) {
            localStorage.setItem(this.key, JSON.stringify(value));
        }
    }

    export class CountFilters extends BaseCache<{ [key: string]: any }> {
        constructor() {
            super(STORAGE_KEYS.COUNT_FILTERS);
        }
        cacheParse(): any {
            return storageJSONSafeParse<{
                [key in NomiaApp.CountFilters]: any;
            }>(this.key, function (_, value) {
                var a;
                if (typeof value === 'string') {
                    a =
                        /[0-9]{4}-[0-9]{2}-[0-9]{2}T[0-9]{2}:[0-9]{2}:[0-9]{2}\.[0-9]{3}Z/.exec(
                            value
                        );
                    if (a) return new Date(value);
                }
                return value;
            });
        }
        validate() {
            const filters = this.cacheParse();
            if (!filters) return;
            if (Object.prototype.toString.call(filters) !== '[object Object]') {
                this.delete();
                return;
            }
        }
        getAndHandleStorage(
            key: NomiaApp.CountFilters
        ): { [key: string]: any } | null {
            const filters = this.cacheParse();
            if (!filters) return null;
            return filters[key];
        }
        save(key: NomiaApp.CountFilters, value: any) {
            let data = this.cacheParse() ?? ({} as any);
            data[key] = value;
            localStorage.setItem(this.key, JSON.stringify(data));
        }
        get(key: NomiaApp.CountFilters) {
            this.validate();
            return this.getAndHandleStorage(key);
        }
    }

    export class AssignedNotices extends BaseCache<string[]> {
        constructor() {
            super(STORAGE_KEYS.ASSIGNED_NOTICES);
        }
        validate() {
            const notices = storageJSONSafeParse<{ [key: string]: string[] }>(
                this.key
            );
            if (!notices) return;
            if (Object.prototype.toString.call(notices) !== '[object Object]') {
                this.delete();
                return;
            }
        }
        getAndHandleStorage(userId : string) {
            const data = storageJSONSafeParse<{ [key: string]: string[] }>(this.key);
            return data?.[userId] ?? null;
        }
        save(userId : string, notices: string[]) {
            let data = storageJSONSafeParse<{ [key: string]: string[] }>(this.key);
            if (!data) data = {};
            data[userId] = notices;
            localStorage.setItem(this.key, JSON.stringify(data));
        }
        get(userId : string) {
            this.validate();
            return this.getAndHandleStorage(userId);
        }
    }
}

class CacheControl {
    static Auth = new CacheManager.Auth();
    static CountFilters = new CacheManager.CountFilters();
    static AssignedNotices = new CacheManager.AssignedNotices();
}

export default CacheControl;
