import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ErrorBoundary } from 'react-error-boundary';
import Routing from 'routes/Routing';

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            retry: 2,
        },
    },
});

function App() {
    return (
        <ErrorBoundary
            FallbackComponent={({ error }) => {
                return (
                    <div>
                        <h1>The Nomia v2 App crashed</h1>
                        <p>{error.message}</p>
                    </div>
                );
            }}
        >
            <QueryClientProvider client={queryClient}>
                <Routing />
            </QueryClientProvider>
        </ErrorBoundary>
    );
}

export default App;
