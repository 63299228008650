import { Badge } from 'primereact/badge';
import { useParams } from 'react-router-dom';
import { countNoticeItem } from 'services/nomia/noticeitem';
import { useProjectQuery } from '../../..';
import NoticeItemCountScreen from '../../components/NoticeItemCountScreen';

const NoticeItemMetrics = () => {
    const params = useParams<{
        projectOid: string;
    }>() as { projectOid: string };

    const projectQuery = useProjectQuery();

    return (
        <NoticeItemCountScreen
            cacheKey='noticeitem'
            title='Item count'
            groupByOptions={[
                {
                    label: 'Last notice status',
                    value: 'last_notice.status',
                    field: 'last_notice_status',
                },
                {
                    label: 'Source',
                    value: 'source',
                },
                {
                    label: 'OnOff status',
                    value: 'onoff_data.is_active',
                    field: 'onoff_data_is_active',
                    body: (data) => {
                        const active = data.onoff_data_is_active;
                        if (active === true)
                            return <Badge value='On' severity='danger' />;
                        if (active === false)
                            return <Badge value='Off' severity='success' />;
                        return <Badge value='Unknown' severity='warning' />;
                    },
                    csvStringifyFunc: (value) => {
                        if (value === true) return 'on';
                        if (value === false) return 'off';
                        return 'unknown';
                    },
                },
                {
                    label: 'Title',
                    value: 'meta.title',
                    field: 'meta_title',
                },
            ]}
            fetchDataCountFn={(options) =>
                countNoticeItem({
                    ...options,
                    filters: {
                        ...options?.filters,
                        project_oid: params.projectOid,
                    },
                })
            }
            filterHandlers={[
                {
                    defaultValue: [],
                    key: 'source|in',
                    handler: (v: string[]) => {
                        if (v.length > 0) return v.join(',');
                        return null;
                    },
                    type: 'chips',
                    label: 'Sources',
                },
                {
                    defaultValue: [],
                    key: 'source_type|in',
                    handler: (v: string[]) => {
                        if (v.length > 0) return v.join(',');
                        return null;
                    },
                    type: 'chips',
                    label: 'Source types',
                },
                {
                    defaultValue: 'any',
                    key: 'onoff_data.is_active',
                    handler: (v) => {
                        if (v !== 'any') return (v === 'on').toString();
                        return null;
                    },
                    label: 'OnOff Status',
                    type: 'dropdown',
                    options: [
                        { value: 'any', label: 'Any' },
                        { value: 'on', label: 'On' },
                        { value: 'off', label: 'Off' },
                    ],
                },
                {
                    defaultValue: [],
                    key: 'meta.title|in',
                    handler: (v: string[]) => {
                        if (v.length > 0) return v.join(',');
                        return null;
                    },
                    type: 'chips',
                    label: 'Title slugs',
                },
            ]}
            exportFilename={`nomia_${projectQuery?.data?.name
                .toLocaleLowerCase()
                .replace(' ', '-')}_item_count`}
        />
    );
};

export default NoticeItemMetrics;
