import Navbar from 'components/Page/Navbar';
import PageWrap from 'components/Page/PageWrap';
import ContentWrap from 'components/Page/ContentWrap';
import { Button } from 'primereact/button';
import { useAuth } from 'hooks/useAuth';
import { Navigate } from 'react-router-dom';

const Login = () => {
    const { status, error, getUDLoginHref } = useAuth();

    if (status === 'loading') return <></>;
    if (status === 'success') return <Navigate to='/' />;

    const loginHref = getUDLoginHref();

    return (
        <PageWrap>
            <Navbar />
            <ContentWrap>
                {status === 'error' && <h2>Error: {error}</h2>}
                {/* <h3>THIS IS CURRENTLY AT ENVIRONMENT TESTING</h3> */}
                <h2>Login to the UserDirectory before proceeding</h2>
                <a href={loginHref}>
                    <Button label='Login' />
                </a>
            </ContentWrap>
        </PageWrap>
    );
};

export default Login;
