import { ReactElement } from 'react';
import {
    BrowserRouter,
    Routes as ReactRoutes,
    Route,
    Navigate,
} from 'react-router-dom';

import { AuthProvider, useAuth } from 'hooks/useAuth';

import Login from 'pages/Login';
import Main from 'pages/Main';

import Home from 'pages/Main/subpages/Home';
import Projects from 'pages/Main/subpages/Projects';

import ProjectSubpages from 'pages/Main/subpages/Projects/subpages/ListProjects/subpages';
import NoticeSubpages from 'pages/Main/subpages/Projects/subpages/ListProjects/subpages/ListNotices/subpages';

import ListProjects from 'pages/Main/subpages/Projects/subpages/ListProjects';

import ListNotices from 'pages/Main/subpages/Projects/subpages/ListProjects/subpages/ListNotices';
import CreateNotice from 'pages/Main/subpages/Projects/subpages/ListProjects/subpages/ListNotices/subpages/CreateNotice';
import CreateManualNotice from 'pages/Main/subpages/Projects/subpages/ListProjects/subpages/ListNotices/subpages/CreateManualNotice';

import ListNoticeItemsFromProject from 'pages/Main/subpages/Projects/subpages/ListProjects/subpages/ListNoticeItems';
import ListNoticeItemsFromNotice from 'pages/Main/subpages/Projects/subpages/ListProjects/subpages/ListNotices/subpages/ListNoticeItems';

import ListCounterNotices from 'pages/Main/subpages/Projects/subpages/ListProjects/subpages/ListCounterNotices';
import ReplyCounterNotice from 'pages/Main/subpages/Projects/subpages/ListProjects/subpages/ListCounterNotices/subpages/ReplyCounterNotice';

import Metrics from 'pages/Main/subpages/Projects/subpages/ListProjects/subpages/Metrics';
import SummaryMetrics from 'pages/Main/subpages/Projects/subpages/ListProjects/subpages/Metrics/subpages/SummaryMetrics';
import NoticeMetrics from 'pages/Main/subpages/Projects/subpages/ListProjects/subpages/Metrics/subpages/NoticeMetrics';
import NoticeItemMetrics from 'pages/Main/subpages/Projects/subpages/ListProjects/subpages/Metrics/subpages/NoticeItemMetrics';

const Routes = () => {
    const ProtectedRoute: React.FC<React.PropsWithChildren> = ({
        children,
    }) => {
        const { status } = useAuth();

        if (status === 'loading')
            return (
                <div style={{ padding: '0px 16px' }}>
                    <h3>Validating login...</h3>
                </div>
            );

        if (status === 'error' || status === 'unauthenticated')
            return <Navigate to='/login' />;

        return children as ReactElement;
    };

    const { permissions } = useAuth();

    return (
        <ReactRoutes>
            <Route path='/login' element={<Login />} />
            <Route
                path='/'
                element={
                    <ProtectedRoute>
                        <Main />
                    </ProtectedRoute>
                }
            >
                <Route index element={<Home />} />
                {permissions?.listProject && (
                    <Route path='projects' element={<Projects />}>
                        <Route index element={<ListProjects />} />
                        <Route path=':projectOid' element={<ProjectSubpages />}>
                            <Route path='notices'>
                                {permissions.listNotice && (
                                    <Route index element={<ListNotices />} />
                                )}
                                <Route
                                    path=':noticeOid'
                                    element={<NoticeSubpages />}
                                >
                                    {permissions.listNoticeItem && (
                                        <Route
                                            path='noticeitems'
                                            element={
                                                <ListNoticeItemsFromNotice />
                                            }
                                        />
                                    )}
                                </Route>
                                {permissions.registerNotice && (
                                    <Route
                                        path='create'
                                        element={<CreateNotice />}
                                    />
                                )}
                                {permissions.registerNotice && (
                                    <Route
                                        path='assign'
                                        element={<CreateManualNotice />}
                                    />
                                )}
                            </Route>
                            {permissions.listNoticeItem && (
                                <Route
                                    path='noticeitems'
                                    element={<ListNoticeItemsFromProject />}
                                />
                            )}
                            {permissions.listNoticeItem && (
                                <Route path='counternotices'>
                                    <Route
                                        index
                                        element={<ListCounterNotices />}
                                    />
                                    <Route
                                        path=':noticeOid'
                                        element={<NoticeSubpages />}
                                    >
                                        {permissions.listNoticeItem && (
                                            <Route
                                                path='noticeitems'
                                                element={
                                                    <ListNoticeItemsFromNotice />
                                                }
                                            />
                                        )}
                                        <Route
                                            path='reply'
                                            element={<ReplyCounterNotice />}
                                        />
                                    </Route>
                                </Route>
                            )}
                            <Route path='metrics'>
                                <Route index element={<Metrics />} />
                                <Route
                                    path='summary'
                                    element={<SummaryMetrics />}
                                />
                                <Route
                                    path='notice'
                                    element={<NoticeMetrics />}
                                />
                                <Route
                                    path='noticeitem'
                                    element={<NoticeItemMetrics />}
                                />
                            </Route>
                        </Route>
                    </Route>
                )}
            </Route>
        </ReactRoutes>
    );
};

const Routing = () => {
    return (
        <BrowserRouter basename={window.ROUTE_BASENAME}>
            <AuthProvider>
                <Routes />
            </AuthProvider>
        </BrowserRouter>
    );
};

export default Routing;
