import api from 'services/api';

const apiUrl = window.API_URL;

export async function authenticateUser(token: string): Promise<Ether.MeInfo> {
    if (!token) throw new Error('Received empty token');

    return await api
        .get<{ success: boolean; payload: Ether.MeInfo }>(`${apiUrl}/me`, {
            headers: {
                'access-token': token,
            },
        })
        .catch(() => {
            throw new Error('Invalid token');
        })
        .then(({ data }) => {
            if (!data) throw new Error('Received empty response from API');

            const { success, payload } = data;

            if (!success) throw new Error('Received an error from API');

            return payload;
        })
        .then(({ user, permissions }: Ether.MeInfo) => ({ user, permissions }));
}

export async function getMyInfo(): Promise<Ether.UserInfo> {
    return await api
        .get<{ success: boolean; payload: Ether.MeInfo }>(`${apiUrl}/me`)
        .catch(() => {
            throw new Error('Invalid token');
        })
        .then(({ data }) => {
            if (!data) throw new Error('Received empty response from API');

            const { success, payload } = data;

            if (!success) throw new Error('Received an error from API');

            return payload;
        })
        .then(({ user }) => user);
}
