import api from 'services/api';

const apiUrl = window.API_URL;

export const getSources = (options?: { signal?: AbortSignal }) => {
    return new Promise<Ether.Nomia.ISource[]>((resolve, reject) => {
        api.get<Ether.IApi<Ether.Nomia.ISource>>(`${apiUrl}/get-sources`, {
            signal: options?.signal,
        })
            .then((res) => {
                resolve(res.data.payload);
            })
            .catch((err) => reject(err));
    });
};

export const getTitles = (options?: { signal?: AbortSignal }) => {
    return new Promise<Ether.Nomia.ITitle[]>((resolve, reject) => {
        api.get<Ether.IApi<Ether.Nomia.ITitle>>(`${apiUrl}/get-titles`, {
            signal: options?.signal,
        })
            .then((res) => {
                resolve(res.data.payload);
            })
            .catch((err) => reject(err));
    });
};

export const countPendingLinksBySource = (projectId : string, options?: {
    signal?: AbortSignal;
}) => {
    return new Promise<Ether.Nomia.IPendingLinkBySource[]>(
        (resolve, reject) => {
            api.get<Ether.IApi<Ether.Nomia.IPendingLinkBySource>>(
                `${apiUrl}/count-pending-noticeitems`,
                {
                    signal: options?.signal,
                    params: {
                        project_oid : projectId,
                        group_by: 'source,source_type,meta.title',
                    },
                }
            )
                .then((res) => {
                    resolve(res.data.payload);
                })
                .catch((err) => reject(err));
        }
    );
};

export const countPendingLinksByProject = (options?: {
    signal?: AbortSignal;
}) => {
    return new Promise<Ether.Nomia.IPendingLinkByProject[]>(
        (resolve, reject) => {
            api.get<Ether.IApi<Ether.Nomia.IPendingLinkByProject>>(
                `${apiUrl}/count-pending-noticeitems`,
                {
                    signal: options?.signal,
                    params: {
                        group_by: 'project_oid',
                    },
                }
            )
                .then((res) => {
                    resolve(res.data.payload);
                })
                .catch((err) => reject(err));
        }
    );
};
