import api from 'services/api';

const apiUrl = window.API_URL;

export const getOneProject = (
    id: string,
    options?: {
        signal?: AbortSignal;
    }
): Promise<Ether.Nomia.IProject> => {
    return new Promise<Ether.Nomia.IProject>((resolve, reject) => {
        api.get<Ether.IApi<Ether.Nomia.IProject>>(`${apiUrl}/list-project`, {
            signal: options?.signal,
            params: {
                _id: id,
            },
        })
            .then((result) => {
                const project = result.data.payload[0];
                resolve(project ?? null);
            })
            .catch(reject);
    });
};

export const listProjects = (options?: {
    signal?: AbortSignal;
    limit?: number;
    offset?: number;
}): Promise<Ether.Nomia.IProject[]> => {
    return new Promise<Ether.Nomia.IProject[]>((resolve, reject) => {
        api.get<Ether.IApi<Ether.Nomia.IProject>>(`${apiUrl}/list-project`, {
            signal: options?.signal,
            params: {
                limit: options?.limit ?? 10,
                offset: options?.offset ?? 0,
            },
        })
            .then((result) => {
                const projects = result.data.payload;
                resolve(projects);
            })
            .catch(reject);
    });
};

export const generateProjectReportCount = <T>(
    projectOid: string,
    options?: {
        signal?: AbortSignal;
        filters?: { [key: string]: any };
    }
) => {
    return new Promise<
        (Partial<T> & {
            notice_count: number;
            noticeitem_count: number;
            compliance: {
                active: number;
                inactive: number;
                percentage: number;
            };
            time_until_down: { avg: number; count: number };
        })[]
    >((resolve, reject) => {
        api.get<{
            success: string;
            meta: { payload_count: number; [key: string]: unknown };
            payload:
                | (Partial<T> & {
                      notice_count: number;
                      noticeitem_count: number;
                      compliance: {
                          active: number;
                          inactive: number;
                          percentage: number;
                      };
                      time_until_down: { avg: number; count: number };
                  })[]
                | {
                      notice_count: number;
                      noticeitem_count: number;
                      compliance: {
                          active: number;
                          inactive: number;
                          percentage: number;
                      };
                      time_until_down: { avg: number; count: number };
                  };
        }>(`${apiUrl}/generate-project-report/${projectOid}`, {
            signal: options?.signal,
            params: {
                ...options?.filters,
            },
            timeout: 15 * 60 * 1000,
        })
            .then((result) => {
                const data = result.data.payload;
                if (Array.isArray(data)) {
                    resolve(data);
                } else {
                    resolve([data] as any);
                }
            })
            .catch(reject);
    });
};
