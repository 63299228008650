import { useParams } from 'react-router-dom';
import { generateProjectReportCount } from 'services/nomia/project';
import { useProjectQuery } from '../../..';
import SummaryCountScreen from '../../components/SummaryCountScreen';

const SummaryMetrics = () => {
    const params = useParams<{
        projectOid: string;
    }>() as { projectOid: string };

    const projectQuery = useProjectQuery();

    return (
        <SummaryCountScreen
            cacheKey='summary'
            title='Project summary'
            groupByOptions={[
                {
                    label: 'Source',
                    value: 'source',
                },
            ]}
            fetchDataCountFn={(options) =>
                generateProjectReportCount(params.projectOid, {
                    ...options,
                    filters: {
                        ...options?.filters,
                    },
                })
            }
            filterHandlers={[
                {
                    defaultValue: [],
                    key: 'source|in',
                    handler: (v: string[]) => {
                        if (v.length > 0) return v.join(',');
                        return null;
                    },
                    type: 'chips',
                    label: 'Sources',
                },
                {
                    defaultValue: null,
                    key: 'sent_at|lt',
                    type: 'datepicker',
                    label: 'Notice sent before',
                },
                {
                    defaultValue: null,
                    key: 'sent_at|gt',
                    type: 'datepicker',
                    label: 'Notice sent after',
                },
            ]}
            exportFilename={`nomia_${projectQuery?.data?.name
                .toLocaleLowerCase()
                .replace(' ', '-')}_summary`}
        />
    );
};

export default SummaryMetrics;
