import './style.css';

const renderIsRequired = (isRequired?: boolean) => {
    if (isRequired == null) return;
    return isRequired ? (
        <strong className='isrequired-util required'>*</strong>
    ) : (
        <i className='isrequired-util optional'>Optional</i>
    );
};

export default renderIsRequired;
