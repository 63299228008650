const storageJSONSafeParse = <T>(
    key: string,
    reviver?: (key: string, value: any) => any
): T | null => {
    const value = localStorage.getItem(key);
    if (!value) return null;

    try {
        const parsedValue = JSON.parse(value, reviver);
        return parsedValue;
    } catch (err) {
        if (err instanceof SyntaxError) {
            localStorage.removeItem(key);
            console.error(err);
            return null;
        }
        throw err;
    }
};

export default storageJSONSafeParse;
