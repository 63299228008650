import {
    DataTableFilterMeta,
    DataTableFilterMetaData,
    DataTableOperatorFilterMetaData,
    DataTableSortMeta,
} from 'primereact/datatable';

import api from 'services/api';
import { baseExportCsv, handleFilterMatchMode } from 'services/utils';
import isValidOid from 'utils/isValidOid';

const apiUrl = window.API_URL;

const handleDataTableFilterMeta = (filters?: DataTableFilterMeta) => {
    if (!filters) return {};
    let paramFilters: { [key: string]: string | number } = {};
    const { key, source, notice_status } = filters;
    if (key) {
        const filterMeta = filters['key'] as DataTableOperatorFilterMetaData;
        paramFilters = {
            ...paramFilters,
            ...handleFilterMatchMode(
                'key',
                filterMeta.constraints[0]?.value,
                filterMeta.constraints[0].matchMode
            ),
        };
    }
    if (source) {
        const filterMeta = filters['source'] as DataTableOperatorFilterMetaData;
        paramFilters = {
            ...paramFilters,
            ...handleFilterMatchMode(
                'source',
                filterMeta.constraints[0]?.value,
                filterMeta.constraints[0].matchMode
            ),
        };
    }
    if (notice_status) {
        paramFilters['notice_status'] = (
            notice_status as DataTableFilterMetaData
        )?.value;
    }
    if (filters['_id']) {
        const filterMeta = filters['_id'] as DataTableFilterMetaData;
        const oid = filterMeta.value;
        if (isValidOid(oid)) paramFilters['_id'] = oid;
    }
    if (filters['onoff_data.is_active']) {
        const value = (
            filters['onoff_data.is_active'] as DataTableFilterMetaData
        )?.value;
        if (value === 'online') {
            paramFilters['onoff_data.is_active@bool'] = 'true';
        } else if (value === 'offline') {
            paramFilters['onoff_data.is_active@bool'] = 'false';
        } else if (value === 'null') {
            paramFilters['onoff_data.is_active|isnull'] = 'true';
        } else if (value === 'not_null') {
            paramFilters['onoff_data.is_active|isnull'] = 'false';
        }
    }
    if (filters['last_notice.status']) {
        paramFilters['last_notice.status'] = (
            filters['last_notice.status'] as DataTableFilterMetaData
        )?.value;
    }
    return paramFilters;
};

export const listNoticeItemsByProject = (
    projectId: string,
    options?: {
        signal?: AbortSignal;
        limit?: number;
        offset?: number;
        filters?: DataTableFilterMeta;
    }
): Promise<Ether.Nomia.INoticeItem[]> => {
    return new Promise<Ether.Nomia.INoticeItem[]>((resolve, reject) => {
        const filters = handleDataTableFilterMeta(options?.filters);

        api.get<Ether.IApi<Ether.Nomia.INoticeItem>>(
            `${apiUrl}/list-noticeitem`,
            {
                signal: options?.signal,
                params: {
                    project_oid: projectId,
                    limit: options?.limit ?? 10,
                    offset: options?.offset ?? 0,
                    ...filters,
                },
            }
        )
            .then((result) => {
                const noticeItems = result.data.payload;
                resolve(noticeItems);
            })
            .catch(reject);
    });
};

export const listNoticeItemsByNotice = (
    noticeId: string,
    projectId: string,
    options?: {
        signal?: AbortSignal;
        limit?: number;
        offset?: number;
        filters?: DataTableFilterMeta;
        fields?: string[];
    }
): Promise<Ether.Nomia.INoticeItem[]> => {
    return new Promise<Ether.Nomia.INoticeItem[]>((resolve, reject) => {
        const filters = handleDataTableFilterMeta(options?.filters);

        api.get<Ether.IApi<Ether.Nomia.INoticeItem>>(
            `${apiUrl}/list-noticeitem`,
            {
                signal: options?.signal,
                params: {
                    'notice_ids@oid': noticeId,
                    project_oid: projectId,
                    limit: options?.limit ?? 10,
                    offset: options?.offset ?? 0,
                    _fields: options?.fields?.join(','),
                    ...filters,
                },
            }
        )
            .then((result) => {
                const noticeItems = result.data.payload;
                resolve(noticeItems);
            })
            .catch(reject);
    });
};

// export const exportNoticeItems = async (
//     projectId: string,
//     options?: {
//         noticeId?: string;
//         filters?: DataTableFilterMeta;
//     }
// ) => {
//     const fetchNoticeItems = (filters: { [key: string]: string | number }) => {
//         return new Promise<Ether.Nomia.INotice[]>((resolve, reject) => {
//             api.get<Ether.IApi<Ether.Nomia.INotice>>(
//                 `${apiUrl}/list-noticeitem`,
//                 {
//                     params: {
//                         project_oid: projectId,
//                         'notice_ids@oid': options?.noticeId,
//                         ...filters,
//                     },
//                 }
//             )
//                 .then((result) => {
//                     const notices = result.data.payload;
//                     resolve(notices);
//                 })
//                 .catch(reject);
//         });
//     };

//     // const cache: { [key: string]: Ether.Nomia.INotice } = {};
//     // const crossFetchNoticeItem = (item: {
//     //     _id: string;
//     //     [key: string]: any;
//     // }) => {
//     //     return new Promise<{ _id: string; [key: string]: any }>((resolve) => {
//     //         if (!item.notice_ids) return resolve(item);
//     //         const promises = item.notice_ids.map((id: string) => {
//     //             return new Promise<Ether.Nomia.INotice>((resolve) => {
//     //                 if (cache[id]) return resolve(cache[id]);
//     //                 getOneNotice(projectId, id).then((notice) => {
//     //                     cache[id] = notice;
//     //                     resolve(cache[id]);
//     //                 });
//     //             });
//     //         });
//     //         const newItem: { _id: string; [key: string]: any } = {
//     //             ...item,
//     //             notices: [],
//     //         };
//     //         Promise.all(promises).then((notices) => {
//     //             newItem.notices = notices.filter((n) => n == null);
//     //             resolve(newItem);
//     //         });
//     //     });
//     // };

//     const csvHeaders: (
//         | string
//         | {
//               field: string;
//               name?: string;
//               parseFunction?(value: any): string;
//           }
//     )[] = [
//         '_id',
//         'key',
//         'project_oid',
//         'value',
//         {
//             field: 'notice_ids',
//             name: 'notice_ids',
//             parseFunction: (value) => value?.join(', '),
//         },
//         'notice_status',
//         'source',
//         'source_type',
//         'onoff_data',
//         'meta',
//         'created_at',
//         'updated_at',
//         {
//             field: 'last_notice.notice_id',
//             name: 'last_notice_id',
//         },
//         {
//             field: 'last_notice.status',
//             name: 'last_notice_status',
//         },
//         {
//             field: 'last_notice.sent_at',
//             name: 'last_notice_sent_at',
//         },
//         {
//             field: 'last_notice.counter_notice.status',
//             name: 'last_notice_counter_status',
//         },
//     ];

//     const filters = handleDataTableFilterMeta(options?.filters);

//     await exportAsCsv({
//         filename:
//             'nomiav2_' +
//             projectId +
//             (options?.noticeId ? `_${options?.noticeId}` : '') +
//             '_items',
//         fetchFn: fetchNoticeItems,
//         filters,
//         csvHeaders,
//         // crossFetchItem: crossFetchNoticeItem,
//     });
// };

export const exportNoticeItems = (
    projectId: string,
    options?: {
        noticeId?: string;
        signal?: AbortSignal;
        filters?: DataTableFilterMeta;
        sort?: DataTableSortMeta;
        onCountUpdate?(count: number, total: number): void;
    }
) => {
    const filters = handleDataTableFilterMeta(options?.filters);
    filters['project_oid'] = projectId;
    if (options?.noticeId) filters['notice_ids@oid'] = options.noticeId;
    const filename =
        'nomiav2_' +
        projectId +
        (options?.noticeId ? `_${options?.noticeId}` : '') +
        '_items';
    return baseExportCsv('/export-noticeitems', {
        ...options,
        filename,
        filters,
    });
};

export const importNoticeItems = async (projectId: string, file: File) => {
    const formData = new FormData();
    formData.append('project_oid', projectId);
    formData.append('file', file);

    const result = await api.post<
        Ether.IApi<{
            created: number;
            updated: number;
            errors: [number, string][];
        }>
    >(`${apiUrl}/import-noticeitems`, formData);

    return result.data.payload;
};

interface IRenotifyNoticeItems {
    updated: number;
    already_pending: number;
    errors: [index: number, reason: string][];
    total: number;
}

export const renotifyNoticeItems = (
    ids: string[],
    options?: {
        signal: AbortSignal;
    }
) => {
    return new Promise<IRenotifyNoticeItems>((resolve, reject) => {
        api.post<{
            success: boolean;
            payload: IRenotifyNoticeItems;
        }>(
            `${apiUrl}/renotify-noticeitems`,
            { payload: ids },
            {
                signal: options?.signal,
            }
        )
            .then((result) => resolve(result.data.payload))
            .catch(reject);
    });
};

export const countNoticeItem = <T>(options?: {
    signal?: AbortSignal;
    filters?: { [key: string]: any };
}) => {
    return new Promise<
        (Partial<T> & {
            count: number;
            time_until_down: { avg: number; count: number };
        })[]
    >((resolve, reject) => {
        api.get<{
            success: string;
            meta: { payload_count: number; [key: string]: unknown };
            payload:
                | (Partial<T> & {
                      count: number;
                      time_until_down: { avg: number; count: number };
                  })[]
                | {
                      count: number;
                      time_until_down: { avg: number; count: number };
                  };
        }>(`${apiUrl}/count-noticeitem`, {
            signal: options?.signal,
            params: {
                ...options?.filters,
                include_time_until_down: true,
            },
            timeout: 15 * 60 * 1000,
        })
            .then((result) => {
                const data = result.data.payload;
                if (Array.isArray(data)) {
                    resolve(data);
                } else {
                    resolve([data] as any);
                }
            })
            .catch(reject);
    });
};
