import { useState } from 'react';
import { useQuery, useMutation } from '@tanstack/react-query';
import { useNavigate, useParams } from 'react-router-dom';

import { Button } from 'primereact/button';
import { DataTableFilterMeta, DataTableSortMeta } from 'primereact/datatable';
import { FilterMatchMode, FilterOperator } from 'primereact/api';

import { exportNotices, listNoticesByProject } from 'services/nomia/notice';

import { Paginator } from 'components/ethercity-primereact';
import { useProjectQuery } from 'pages/Main/subpages/Projects/subpages/ListProjects/subpages';
import { ProgressBar } from 'primereact/progressbar';
import NoticesDataTable from './components';

import { useAuth } from 'hooks/useAuth';

const ListNotices = () => {
    const { permissions } = useAuth();

    const projectQuery = useProjectQuery();

    const [downloadProgress, setDownloadProgress] = useState(0.0);

    const navigate = useNavigate();
    const params = useParams<{
        projectOid: string;
    }>() as { projectOid: string };

    const [pageOptions, setPageOptions] = useState<{
        page: number;
        rows: number;
    }>({ page: 1, rows: 50 });

    const [filters, setFilters] = useState<DataTableFilterMeta>({
        _id: {
            operator: FilterOperator.AND,
            constraints: [
                {
                    value: null,
                    matchMode: FilterMatchMode.EQUALS,
                },
            ],
        },
        'target.source': {
            operator: FilterOperator.AND,
            constraints: [
                {
                    value: null,
                    matchMode: FilterMatchMode.CONTAINS,
                },
            ],
        },
        'target.source_type': {
            operator: FilterOperator.AND,
            constraints: [
                {
                    value: null,
                    matchMode: FilterMatchMode.CONTAINS,
                },
            ],
        },
        status: {
            value: null,
            matchMode: FilterMatchMode.IN,
        },
        sent_at: {
            operator: FilterOperator.AND,
            constraints: [
                {
                    value: null,
                    matchMode: FilterMatchMode.DATE_AFTER,
                },
            ],
        },
    });

    const [sort, setSort] = useState<{
        sortField: undefined | string;
        sortOrder: DataTableSortMeta['order'] | null;
    }>({
        sortField: undefined,
        sortOrder: null,
    });

    const {
        data: noticesData,
        error: noticesError,
        status: noticesStatus,
        refetch: refetchNotices,
        remove: removeNotices,
    } = useQuery<Ether.Nomia.INotice[], Error>(
        ['list-notices', params.projectOid, pageOptions, filters, sort],
        async ({ signal }): Promise<Ether.Nomia.INotice[]> => {
            if (!params.projectOid) throw Error('projectOid not defined');

            return listNoticesByProject(params.projectOid, {
                filters,
                signal,
                limit: pageOptions.rows,
                offset: (pageOptions.page - 1) * pageOptions.rows,
                sortField: sort.sortField,
                sortOrder: sort.sortOrder,
            });
        }
    );

    const exportNoticesMutation = useMutation(async (): Promise<void> => {
        await exportNotices(params.projectOid, 'notice', {
            filters,
            onCountUpdate: (count, total) => {
                if (count > total) setDownloadProgress(100);
                else
                    setDownloadProgress(
                        Math.round((count / total) * 10000) / 100
                    );
            },
        });
    });

    return (
        <div>
            <h2>{projectQuery?.data?.name} - Notices</h2>
            <div style={{ display: 'flex', gap: '8px' }}>
                {permissions?.registerNotice && (
                    <Button
                        className='p-button-outlined'
                        icon='pi pi-plus'
                        label='Add Notice'
                        onClick={() => navigate('create')}
                    />
                )}
                {permissions?.countNoticeItem &&
                    permissions?.getTitle &&
                    permissions?.getSources && (
                        <Button
                            className='p-button-outlined'
                            icon='pi pi-pencil'
                            label='Assign Notice'
                            onClick={() => navigate('assign')}
                        />
                    )}
                {permissions?.exportNotices && (
                    <Button
                        className='p-button-outlined'
                        icon='pi pi-download'
                        label='Export Notices'
                        onClick={() => exportNoticesMutation.mutate()}
                        loading={exportNoticesMutation.isLoading}
                    />
                )}
            </div>
            {exportNoticesMutation.isLoading && (
                <ProgressBar
                    value={downloadProgress}
                    style={{ marginTop: '8px', marginBottom: '8px' }}
                />
            )}
            {noticesError ? (
                noticesError.toString()
            ) : (
                <>
                    <Paginator
                        disableNext={
                            !noticesData ||
                            noticesData.length < pageOptions.rows
                        }
                        page={pageOptions.page}
                        rows={pageOptions.rows}
                        onPageChange={setPageOptions}
                        onRefresh={() => {
                            removeNotices();
                            refetchNotices();
                        }}
                        showRefresh
                        isRefreshing={noticesStatus === 'loading'}
                    />
                    <NoticesDataTable
                        data={noticesData}
                        isLazy={true}
                        projectId={params.projectOid}
                        loading={noticesStatus === 'loading'}
                        filterOptions={{
                            filters,
                            onFilter: (e) => setFilters(e.filters),
                        }}
                        sortOptions={{
                            sortOrder: sort.sortOrder,
                            sortField: sort.sortField,
                            onSort: (e) =>
                                setSort({
                                    sortField: e.sortField,
                                    sortOrder: e.sortOrder,
                                }),
                        }}
                        refetchNotices={refetchNotices}
                    />
                </>
            )}
        </div>
    );
};

export default ListNotices;
