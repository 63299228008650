import React, { ReactNode, useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { Link } from 'react-router-dom';
import { stringify } from 'csv-stringify/browser/esm/sync';

import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';

import CacheControl from 'controller/cache/cacheController';

import { Dropdown } from 'components/ethercity-primereact/components/input/Dropdown';
import { MultiSelect } from 'components/ethercity-primereact/components/input/MultiSelect';
import { Chips } from 'components/ethercity-primereact/components/input/Chips';
import { Datepicker } from 'components/ethercity-primereact/components/input/Datepicker';
import { Paginator } from 'components/ethercity-primereact/components/utils/Paginator';

import './style.css';
import downloadFromUrl from 'utils/downloadFromUrl';

import { getReadableAverageTimeUntilDown } from '../utils';

type FilterTypes = 'chips' | 'dropdown' | 'datepicker' | 'custom';

const normalizeCompliance = (value: number | null) => {
    if (!value) return '-';
    return (Math.floor(value * 100) / 100).toString() + '%';
};

const getFilterElement = (
    type: FilterTypes,
    key: string,
    value: any,
    setValue: React.Dispatch<React.SetStateAction<any>>,
    config?: {
        options?: { label: string; value: any }[];
        label?: string;
        customElement?: (v: any, onChange: (newValue: any) => any) => ReactNode;
        customProps?: { [key: string]: any };
    }
): ReactNode => {
    const dispatch = (newValue: any) => {
        setValue((old: any) => ({ ...old, [key]: newValue }));
    };

    switch (type) {
        case 'custom':
            if (!config?.customElement)
                throw new Error('type custom must have a customElement set');
            return config.customElement(value, dispatch);
        case 'datepicker':
            return (
                <Datepicker
                    value={value}
                    onChange={(v) => dispatch(v)}
                    label={config?.label}
                    {...config?.customProps}
                />
            );
        case 'dropdown':
            return (
                <Dropdown
                    value={value}
                    onChange={(e) => dispatch(e.target.value)}
                    label={config?.label}
                    options={config?.options ?? []}
                    {...config?.customProps}
                />
            );
        case 'chips':
            return (
                <Chips
                    value={value}
                    onChange={(e) => dispatch(e.target.value)}
                    label={config?.label}
                    addOnBlur
                    allowDuplicate={false}
                    {...config?.customProps}
                />
            );
    }
};

interface ISummaryCountScreen {
    title: string;
    cacheKey: NomiaApp.CountFilters;
    fetchDataCountFn(o?: {
        signal?: AbortSignal | undefined;
        filters?:
            | {
                  [key: string]: any;
              }
            | undefined;
    }): Promise<(any & { count: number })[]>;
    groupByOptions: {
        label: string;
        value: string;
        field?: string;
        body?(row: any): ReactNode;
        isDefault?: boolean;
        csvStringifyFunc?: (value: any) => string;
    }[];
    filterHandlers: {
        key: string;
        defaultValue: any;
        // element(value: any, onChange: (v: any) => void): ReactNode;
        type: FilterTypes;
        label?: string;
        options?: {
            label: string;
            value: any;
        }[];
        handler?(value: any): string | undefined | null;
        customElement?: (v: any, onChange: (newValue: any) => any) => ReactNode;
        customProps?: { [key: string]: string };
    }[];
    exportFilename?: string;
}

const SummaryCountScreen: React.FC<ISummaryCountScreen> = ({
    title,
    cacheKey,
    fetchDataCountFn,
    groupByOptions,
    filterHandlers,
    exportFilename,
}) => {
    const [pageOptions, setPageOptions] = useState({
        page: 1,
        rows: 50,
    });
    const [first, setFirst] = useState(
        (pageOptions.page - 1) * pageOptions.rows
    );
    useEffect(() => {
        setFirst((pageOptions.page - 1) * pageOptions.rows);
    }, [pageOptions]);

    const [filters, setFilters] = useState(() => {
        const cacheData = CacheControl.CountFilters.get(cacheKey) ?? {};
        const filters: { [key: string]: any } = {
            group_by:
                cacheData['group_by'] ??
                groupByOptions.filter((g) => g.isDefault).map((g) => g.field),
        };
        filterHandlers.forEach(
            (f) => (filters[f.key] = cacheData[f.key] ?? f.defaultValue)
        );
        return filters;
    });

    useEffect(
        () => CacheControl.CountFilters.save(cacheKey, filters),
        [cacheKey, filters]
    );

    const [appliedFilters, setAppliedFilters] = useState<{
        [key: string]: any;
    } | null>(null);

    const dataCountQuery = useQuery(
        [cacheKey, appliedFilters],
        async () => {
            const data = await fetchDataCountFn({
                filters: appliedFilters ?? {},
            });
            return data;
        },
        {
            enabled: !!appliedFilters,
        }
    );

    const handleApplyFilters = () => {
        const final: { [key: string]: string } = {
            group_by: filters.group_by?.join(','),
        };
        if (!final.group_by || final.group_by === '') delete final.group_by;

        filterHandlers.forEach((f) => {
            const res = f.handler ? f.handler(filters[f.key]) : filters[f.key];
            if (res) final[f.key] = res;
        });

        setAppliedFilters(final);
    };

    const generateCsv = () => {
        if (!appliedFilters || !dataCountQuery.data) return;
        if (dataCountQuery.data.length === 0) return;
        const selectedGroups = appliedFilters['group_by'].split(
            ','
        ) as string[];

        const items = [
            ...groupByOptions
                .filter(
                    (g) => selectedGroups.findIndex((s) => s === g.value) > -1
                )
                .map((g) => ({
                    label: g.label,
                    field: g.field ?? g.value,
                    func: g.csvStringifyFunc,
                })),
            {
                label: 'Notices',
                field: 'notice_count',
                func: (value: any) => value,
            },
            {
                label: 'Items',
                field: 'noticeitem_count',
                func: (value: any) => value,
            },
        ];

        const headers = [
            ...items.map((i) => i.label.toString()),
            'Compliance',
            'Items taken down',
            'Average time until',
        ];

        const values = [] as string[][];
        dataCountQuery.data.forEach((data) => {
            const newValue = [] as string[];
            items.forEach((i) => {
                if (i.func) newValue.push(i.func(data[i.field]));
                else newValue.push(data[i.field]?.toString());
            });
            newValue.push(normalizeCompliance(data.compliance.percentage));
            newValue.push(data.time_until_down.count);
            newValue.push(
                getReadableAverageTimeUntilDown(data.time_until_down.avg)
            );
            values.push(newValue);
        });

        const output = stringify(values, {
            header: true,
            columns: headers,
        });
        const blob = new Blob([output]);
        const fileDownloadUrl = URL.createObjectURL(blob);
        downloadFromUrl(
            fileDownloadUrl,
            exportFilename ?? `nomia_${cacheKey}_${new Date().toISOString()}`,
            '.csv'
        );
        URL.revokeObjectURL(fileDownloadUrl);
    };

    const PaginatorWrap: React.FC<{ hideExport?: boolean }> = ({
        hideExport,
    }) => {
        return (
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: '16px',
                }}
            >
                <Paginator
                    page={pageOptions.page}
                    rows={pageOptions.rows}
                    maxPages={
                        dataCountQuery.data && dataCountQuery.data.length !== 0
                            ? Math.ceil(
                                  dataCountQuery.data.length / pageOptions.rows
                              )
                            : 1
                    }
                    onPageChange={setPageOptions}
                    showRefresh
                    onRefresh={dataCountQuery.refetch}
                    disableNext={
                        dataCountQuery.data &&
                        pageOptions.page * pageOptions.rows >=
                            dataCountQuery.data?.length
                    }
                    rowsPerPageOptions={[10, 50, 100, 500]}
                />
                {!hideExport && (
                    <Button
                        icon='pi pi-download'
                        label='Export CSV'
                        onClick={generateCsv}
                        disabled={
                            !dataCountQuery.data ||
                            dataCountQuery.data.length === 0
                        }
                        style={{ marginBottom: '8px' }}
                    />
                )}
            </div>
        );
    };

    return (
        <div style={{ marginTop: '8px' }}>
            <Link to='..'>
                <Button label='Back' />
            </Link>
            <h2>{title}</h2>
            <div className='filter-group'>
                <MultiSelect
                    label='Group by'
                    value={filters.group_by}
                    onChange={(e) =>
                        setFilters((old) => ({
                            ...old,
                            group_by: e.target.value,
                        }))
                    }
                    display={
                        (filters.group_by?.length ?? 0) <= 3 ? 'chip' : 'comma'
                    }
                    maxSelectedLabels={3}
                    options={groupByOptions}
                />
                {filterHandlers.map((f) => (
                    <React.Fragment key={f.key}>
                        {getFilterElement(
                            f.type,
                            f.key,
                            filters[f.key],
                            setFilters,
                            {
                                label: f.label,
                                options: f.options,
                                customElement: f.customElement,
                                customProps: f.customProps,
                            }
                        )}
                    </React.Fragment>
                ))}
                <Button label='Generate metrics' onClick={handleApplyFilters} />
            </div>
            {(dataCountQuery.isFetching || dataCountQuery.isSuccess) &&
                (dataCountQuery.isFetching ? (
                    'Loading count...'
                ) : (
                    <>
                        <h3>
                            {'Total notices: ' +
                                dataCountQuery.data
                                    ?.map((a) => a.notice_count)
                                    .reduce((a, b) => a + b, 0)}
                        </h3>
                        <h3>
                            {'Total items: ' +
                                dataCountQuery.data
                                    ?.map((a) => a.noticeitem_count)
                                    .reduce((a, b) => a + b, 0)}
                        </h3>
                        {appliedFilters?.group_by ? (
                            <>
                                <PaginatorWrap />
                                <DataTable
                                    loading={dataCountQuery.isFetching}
                                    value={dataCountQuery.data}
                                    removableSort
                                    paginator
                                    rows={pageOptions.rows}
                                    first={first}
                                    onPage={() => {}}
                                    paginatorTemplate={''}
                                >
                                    {groupByOptions
                                        .filter(
                                            (o) =>
                                                appliedFilters?.group_by
                                                    ?.split(',')
                                                    .findIndex(
                                                        (s: string) =>
                                                            s === o.value
                                                    ) !== -1
                                        )
                                        .map((o) => (
                                            <Column
                                                key={o.value}
                                                field={o.field ?? o.value}
                                                header={o.label}
                                                body={o.body}
                                                sortable
                                            />
                                        ))}
                                    <Column
                                        field='notice_count'
                                        header='Notices'
                                        sortable
                                    />
                                    <Column
                                        field='noticeitem_count'
                                        header='Items'
                                        sortable
                                    />
                                    <Column
                                        field='compliance.percentage'
                                        header='Compliance'
                                        sortable
                                        body={(data) => {
                                            return normalizeCompliance(
                                                data.compliance.percentage
                                            );
                                        }}
                                    />
                                    <Column
                                        field='time_until_down.count'
                                        header='Items taken down'
                                        sortable
                                    />
                                    <Column
                                        field='time_until_down.avg'
                                        header='Average time until down'
                                        sortable
                                        body={(data) => {
                                            return getReadableAverageTimeUntilDown(
                                                data.time_until_down.avg
                                            );
                                        }}
                                    />
                                </DataTable>
                                <PaginatorWrap hideExport />
                            </>
                        ) : (
                            dataCountQuery.isSuccess && (
                                <>
                                    <h3>
                                        Compliance:{' '}
                                        {normalizeCompliance(
                                            dataCountQuery.data[0].compliance
                                                .percentage
                                        )}
                                    </h3>
                                    <h3>
                                        Items taken down:{' '}
                                        {
                                            dataCountQuery.data[0]
                                                .time_until_down.count
                                        }
                                    </h3>
                                    <h3>
                                        Average time until down:{' '}
                                        {getReadableAverageTimeUntilDown(
                                            dataCountQuery.data[0]
                                                .time_until_down.avg
                                        )}
                                    </h3>
                                </>
                            )
                        )}
                    </>
                ))}
        </div>
    );
};

export default SummaryCountScreen;
