import { useRef, useState } from 'react';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { Link, useParams } from 'react-router-dom';

import { Column, ColumnFilterElementTemplateOptions } from 'primereact/column';
import { Button } from 'primereact/button';
import { Badge, BadgeProps } from 'primereact/badge';
import {
    DataTable,
    DataTableFilterMeta,
    DataTableOperatorFilterMetaData,
    DataTableSortMeta,
} from 'primereact/datatable';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { Dropdown } from 'primereact/dropdown';
import { Tooltip } from 'primereact/tooltip';

import { exportNotices, listNoticesByProject } from 'services/nomia/notice';
import {
    exportNoticeItems,
    listNoticeItemsByNotice,
} from 'services/nomia/noticeitem';

import {
    DateBadge,
    InputText,
    ObjectDisplayModal,
    Paginator,
} from 'components/ethercity-primereact';

import { useProjectQuery } from 'pages/Main/subpages/Projects/subpages/ListProjects/subpages';
import { useToast } from 'hooks/useToast';
import { useAuth } from 'hooks/useAuth';
import OidColumn from 'components/Misc/OidColumn';
import { ProgressBar } from 'primereact/progressbar';

const defaultCounterNoticeStatus = [
    'received',
    'responded_queued',
    'responded_sent',
    'expired',
];

function capitalizeFirstLetter(str: string) {
    return str.charAt(0).toUpperCase() + str.slice(1);
}

const ListCounterNotices = () => {
    const { permissions } = useAuth();

    const toast = useToast();
    const queryClient = useQueryClient();
    const firstCheck = useRef(true);

    const [downloadProgress, setDownloadProgress] = useState(0.0);

    const [selectedNotice, setSelectedNotice] =
        useState<Ether.Nomia.INotice | null>(null);

    const params = useParams<{
        projectOid: string;
    }>() as { projectOid: string };

    const [pageOptions, setPageOptions] = useState<{
        page: number;
        rows: number;
    }>({ page: 1, rows: 50 });

    const [filters, setFilters] = useState<DataTableFilterMeta>({
        _id: {
            operator: FilterOperator.AND,
            constraints: [
                {
                    value: null,
                    matchMode: FilterMatchMode.EQUALS,
                },
            ],
        },
        'target.source': {
            operator: FilterOperator.AND,
            constraints: [
                {
                    value: null,
                    matchMode: FilterMatchMode.CONTAINS,
                },
            ],
        },
        'target.source_type': {
            operator: FilterOperator.AND,
            constraints: [
                {
                    value: null,
                    matchMode: FilterMatchMode.CONTAINS,
                },
            ],
        },
        'counter_notice.status': {
            operator: FilterOperator.AND,
            constraints: [
                {
                    value: null,
                    matchMode: FilterMatchMode.IN,
                },
            ],
        },
        'counter_notice.received_at': {
            operator: FilterOperator.AND,
            constraints: [
                {
                    value: null,
                    matchMode: FilterMatchMode.DATE_AFTER,
                },
            ],
        },
        'counter_notice.last_sent_at': {
            operator: FilterOperator.AND,
            constraints: [
                {
                    value: null,
                    matchMode: FilterMatchMode.DATE_AFTER,
                },
            ],
        },
    });

    const [sort, setSort] = useState<{
        field: undefined | string;
        order: DataTableSortMeta['order'];
    }>({
        field: undefined,
        order: null,
    });

    const [hoveredNotice, setHorevedNoticed] =
        useState<Ether.Nomia.INotice | null>(null);
    const [currentDownloadingNoticeId, setCurrentDownloadingNoticeId] =
        useState<string | null>(null);

    const projectQuery = useProjectQuery();

    const {
        data: noticesData,
        error: noticesError,
        status: noticesStatus,
        refetch: refetchNotices,
        remove: removeNotices,
    } = useQuery<Ether.Nomia.INotice[], Error>(
        ['list-counter-notices', params.projectOid, pageOptions, filters, sort],
        async ({ signal }): Promise<Ether.Nomia.INotice[]> => {
            if (!params.projectOid) throw Error('projectOid not defined');

            const finalFilters: {
                [key: string]: DataTableOperatorFilterMetaData;
            } = JSON.parse(JSON.stringify(filters));

            const selectedStatus = (
                finalFilters[
                    'counter_notice.status'
                ] as DataTableOperatorFilterMetaData
            ).constraints[0].value;

            if (selectedStatus == null) {
                (
                    finalFilters[
                        'counter_notice.status'
                    ] as DataTableOperatorFilterMetaData
                ).constraints[0].value = defaultCounterNoticeStatus;
            }

            return listNoticesByProject(params.projectOid, {
                filters: finalFilters,
                signal,
                limit: pageOptions.rows,
                offset: (pageOptions.page - 1) * pageOptions.rows,
                sortField: sort.field,
                sortOrder: sort.order,
            });
        },
        {
            onSuccess: (res) => {
                if (firstCheck.current === true) {
                    if (res.length <= 0) {
                        setFilters((old) => ({
                            ...old,
                            'counter_notice.status': {
                                operator: FilterOperator.AND,
                                constraints: [
                                    {
                                        value: '@any',
                                        matchMode: FilterMatchMode.IN,
                                    },
                                ],
                            },
                        }));
                    }
                    firstCheck.current = false;
                }
            },
        }
    );

    const exportCounterNoticesMutation = useMutation(
        async (): Promise<void> => {
            await exportNotices(params.projectOid, 'counter_notice', {
                filters,
                onCountUpdate: (count, total) => {
                    if (count > total) setDownloadProgress(100);
                    else
                        setDownloadProgress(
                            Math.round((count / total) * 10000) / 100
                        );
                },
            });
        }
    );

    const exportNoticeItemsMutation = useMutation(
        async (noticeId: string): Promise<void> => {
            setCurrentDownloadingNoticeId(noticeId);
            exportNoticeItems(params.projectOid, {
                noticeId: noticeId,
                filters,
                sort: !!sort.field ? (sort as DataTableSortMeta) : undefined,
                onCountUpdate: (count, total) => {
                    if (count > total) setDownloadProgress(100);
                    else
                        setDownloadProgress(
                            Math.round((count / total) * 10000) / 100
                        );
                },
            });
        },
        {
            onSettled: () => setCurrentDownloadingNoticeId(null),
        }
    );

    const copyNoticeItemsToClipboardMutation = useMutation(
        async (noticeId: string) => {
            setCurrentDownloadingNoticeId(noticeId);
            const noticeItems = await queryClient.fetchQuery(
                ['list-noticeitems', params.projectOid, noticeId],
                () =>
                    listNoticeItemsByNotice(noticeId, params.projectOid, {
                        limit: 10000,
                        fields: ['value'],
                    })
            );
            if (noticeItems.length === 0) {
                toast?.show({
                    severity: 'error',
                    summary: 'Notice has no items',
                });
                return;
            }
            const clipboardContent = noticeItems.map((n) => n.value).join('\n');
            navigator.clipboard.writeText(clipboardContent).then(() =>
                toast?.show({
                    severity: 'success',
                    summary: 'Items copied to clipboard',
                    life: 3000,
                })
            );
        },
        {
            onSettled: () => setCurrentDownloadingNoticeId(null),
        }
    );

    const renderActionButtons = (rowData: Ether.Nomia.INotice) => {
        return (
            <div className='action-buttons-wrap'>
                <Button
                    className='p-button-outlined tooltip-button'
                    label='Items'
                    onMouseEnter={() => setHorevedNoticed(rowData)}
                />
                {rowData.counter_notice?.status === 'received' && (
                    <Link to={`${rowData._id}/reply`}>
                        <Button className='p-button-outlined' label='Reply' />
                    </Link>
                )}
            </div>
        );
    };

    const renderCounterNoticeStatus = (rowData: Ether.Nomia.INotice) => {
        const status: Ether.Nomia.CounterNoticeStatus | undefined =
            rowData.counter_notice?.status;
        if (!status) return <Badge value={'-'} />;

        let severity: BadgeProps['severity'] = 'info';
        let text: string;

        if (status === 'received') {
            text = 'Received';
            severity = 'danger';
        } else if (status === 'responded_queued') {
            text = 'Queued';
            severity = 'warning';
        } else if (status === 'responded_sent') {
            text = 'Sent';
            severity = 'success';
        } else if (status === 'expired') {
            text = 'Expired';
            severity = 'danger';
        } else {
            text = capitalizeFirstLetter(status);
        }

        return <Badge value={text} severity={severity} />;
    };

    const counterNoticeStatusFilterTemplate = (
        options: ColumnFilterElementTemplateOptions
    ) => {
        const dropdownOptions: {
            label: string;
            value: Ether.Nomia.CounterNoticeStatus | '@any';
        }[] = [
            { label: 'Received', value: 'received' },
            { label: 'Queued', value: 'responded_queued' },
            { label: 'Sent', value: 'responded_sent' },
            { label: 'Expired', value: 'expired' },
            { label: 'All', value: '@any' },
        ];

        return (
            <Dropdown
                value={options.value}
                options={dropdownOptions}
                onChange={(e) => options.filterCallback(e.value, options.index)}
                placeholder='Select status'
                className='p-column-filter'
            />
        );
    };

    const sourceFilterTemplate = (
        options: ColumnFilterElementTemplateOptions
    ) => {
        return (
            <InputText
                value={options.value ?? ''}
                onChange={(e) =>
                    options.filterCallback(e.target.value, options.index)
                }
                placeholder='ex. dood'
                className='p-column-filter'
            />
        );
    };

    const sourceTypeFilterTemplate = (
        options: ColumnFilterElementTemplateOptions
    ) => {
        return (
            <InputText
                value={options.value ?? ''}
                onChange={(e) =>
                    options.filterCallback(e.target.value, options.index)
                }
                placeholder='ex. cyberlocker'
                className='p-column-filter'
            />
        );
    };

    const sentAtFilterTemplate = (
        options: ColumnFilterElementTemplateOptions
    ) => {
        return (
            <input
                className='ether-datetime'
                type='date'
                value={options.value ?? ''}
                onChange={(e) =>
                    options.filterCallback(e.target.value, options.index)
                }
            />
        );
    };

    return (
        <div>
            <Tooltip
                target='.tooltip-button'
                autoHide={false}
                position='left'
                style={{ marginLeft: '14px' }}
            >
                {hoveredNotice && (
                    <div style={{ display: 'flex', gap: '8px' }}>
                        <Link to={`${hoveredNotice._id}/noticeitems`}>
                            <Button
                                className='p-button-outlined'
                                label='List'
                            />
                        </Link>
                        <Button
                            className='p-button-outlined'
                            label='Copy'
                            onClick={() =>
                                copyNoticeItemsToClipboardMutation.mutate(
                                    hoveredNotice._id
                                )
                            }
                            disabled={
                                (hoveredNotice.noticeitem_count ?? 0) === 0 ||
                                exportNoticeItemsMutation.isLoading ||
                                copyNoticeItemsToClipboardMutation.isLoading
                            }
                            loading={
                                currentDownloadingNoticeId ===
                                    hoveredNotice._id &&
                                copyNoticeItemsToClipboardMutation.isLoading
                            }
                        />
                        <Button
                            className='p-button-outlined'
                            label='Download'
                            onClick={() =>
                                exportNoticeItemsMutation.mutate(
                                    hoveredNotice._id
                                )
                            }
                            disabled={
                                (hoveredNotice.noticeitem_count ?? 0) === 0 ||
                                exportNoticeItemsMutation.isLoading ||
                                exportCounterNoticesMutation.isLoading ||
                                copyNoticeItemsToClipboardMutation.isLoading
                            }
                            loading={
                                currentDownloadingNoticeId ===
                                    hoveredNotice._id &&
                                exportNoticeItemsMutation.isLoading
                            }
                        />
                    </div>
                )}
            </Tooltip>
            <ObjectDisplayModal
                displayData={selectedNotice}
                visible={!!selectedNotice}
                onHide={() => setSelectedNotice(null)}
            />
            <h2>{projectQuery?.data?.name} - Counter-Notices</h2>
            <div style={{ display: 'flex', gap: '8px' }}>
                {permissions?.exportNotices && (
                    <Button
                        className='p-button-outlined'
                        icon='pi pi-download'
                        label='Export Counter-Notices'
                        onClick={() => exportCounterNoticesMutation.mutate()}
                        loading={exportCounterNoticesMutation.isLoading}
                        disabled={exportNoticeItemsMutation.isLoading}
                    />
                )}
            </div>
            {(exportCounterNoticesMutation.isLoading ||
                exportNoticeItemsMutation.isLoading) && (
                <ProgressBar
                    value={downloadProgress}
                    style={{ marginTop: '8px', marginBottom: '8px' }}
                />
            )}
            {noticesError ? (
                noticesError.toString()
            ) : (
                <>
                    <Paginator
                        disableNext={
                            !noticesData ||
                            noticesData.length < pageOptions.rows
                        }
                        page={pageOptions.page}
                        rows={pageOptions.rows}
                        onPageChange={setPageOptions}
                        onRefresh={() => {
                            removeNotices();
                            refetchNotices();
                        }}
                        showRefresh
                        isRefreshing={noticesStatus === 'loading'}
                    />
                    <DataTable
                        lazy
                        value={noticesData}
                        loading={noticesStatus === 'loading'}
                        emptyMessage='No counter-notices available'
                        filters={filters}
                        onFilter={(e) => {
                            setFilters(e.filters);
                        }}
                        onSort={(e) =>
                            setSort({
                                field: e.sortField,
                                order: e.sortOrder,
                            })
                        }
                        sortField={sort.field}
                        sortOrder={sort.order}
                        removableSort
                    >
                        {OidColumn()}
                        <Column
                            field='target.source'
                            header='Source'
                            filter
                            filterElement={sourceFilterTemplate}
                            showAddButton={false}
                            showFilterOperator={false}
                        />
                        <Column
                            field='target.source_type'
                            header='Source Type'
                            filter
                            filterElement={sourceTypeFilterTemplate}
                            showAddButton={false}
                            showFilterOperator={false}
                        />
                        <Column
                            field='counter_notice.status'
                            header='Counter status'
                            body={renderCounterNoticeStatus}
                            filter
                            filterElement={counterNoticeStatusFilterTemplate}
                            showFilterMatchModes={false}
                            showFilterOperator={false}
                            showFilterMenuOptions={false}
                        />
                        <Column
                            field='counter_notice.created_at'
                            header='Counter received at'
                            body={(rowData: Ether.Nomia.INotice) => {
                                return rowData.counter_notice?.created_at ? (
                                    <DateBadge
                                        value={
                                            rowData['counter_notice'][
                                                'created_at'
                                            ]
                                        }
                                    />
                                ) : (
                                    <Badge value={'-'} />
                                );
                            }}
                            dataType='date'
                            filter
                            filterElement={sentAtFilterTemplate}
                            sortable
                            showAddButton={false}
                            showFilterOperator={false}
                        />
                        <Column
                            field='counter_notice.counter_notice.response.responded_at'
                            header='Counter replied at'
                            body={(rowData: Ether.Nomia.INotice) => {
                                return rowData.counter_notice?.response
                                    ?.responded_at ? (
                                    <DateBadge
                                        value={
                                            rowData['counter_notice'][
                                                'response'
                                            ]['responded_at']
                                        }
                                    />
                                ) : (
                                    <Badge value={'-'} />
                                );
                            }}
                            dataType='date'
                            filter
                            filterElement={sentAtFilterTemplate}
                            sortable
                            showAddButton={false}
                            showFilterOperator={false}
                        />
                        <Column
                            field='counter_notice.response.approved'
                            header='Counter approved?'
                            body={(rowData: Ether.Nomia.INotice) => {
                                return rowData.counter_notice?.response
                                    ?.approved == null ? (
                                    <Badge value={'-'} />
                                ) : rowData.counter_notice?.response
                                      ?.approved ? (
                                    <Badge value='yes' severity='success' />
                                ) : (
                                    <Badge value='no' severity='danger' />
                                );
                            }}
                        />
                        <Column
                            field='details'
                            header='Details'
                            body={(rowData: Ether.Nomia.INotice) => (
                                <Button
                                    icon='pi pi-search'
                                    className='p-button-outlined'
                                    onClick={() => setSelectedNotice(rowData)}
                                />
                            )}
                        />
                        <Column header='Actions' body={renderActionButtons} />
                    </DataTable>
                </>
            )}
        </div>
    );
};

export default ListCounterNotices;
