import api from 'services/api';

const apiUrl = window.API_URL;

export const handleUserDirectoryCallback = (
    hash: string,
    options?: {
        signal?: AbortSignal;
    }
) => {
    return new Promise<Ether.UDCallback>((resolve, reject) => {
        api.post<Ether.UDCallback>(
            apiUrl + '/auth/ud-callback',
            {
                hash,
            },
            {
                signal: options?.signal,
            }
        )
            .then((res) => {
                resolve(res.data);
            })
            .catch((err) => reject(err));
    });
};
