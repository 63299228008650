import { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { Link, Outlet, useLocation, useParams } from 'react-router-dom';

import { MenuItem } from 'primereact/menuitem';
import { BreadCrumb } from 'primereact/breadcrumb';

import { getOneProject } from 'services/nomia/project';

import LoadingSpinner from 'components/Misc/LoadingSpinner';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorFallback from 'components/ethercity-primereact/components/utils/ErrorFallback';

function capitalizeFirstLetter(str: string) {
    return str.charAt(0).toUpperCase() + str.slice(1);
}

const Projects = () => {
    const location = useLocation();

    const params = useParams<{
        projectOid: string;
    }>();

    const [breadCrumbItems, setBreadCrumbItems] = useState<MenuItem[]>([]);

    const {
        data: currentProject,
        error,
        isFetching,
    } = useQuery<Ether.Nomia.IProject | null, Error>(
        ['get-one-project-maybe', params.projectOid],
        async (): Promise<Ether.Nomia.IProject | null> => {
            if (!params.projectOid) return null;
            const project = await getOneProject(params.projectOid);
            return project;
        }
    );

    const isAvailable = (path: string) => {
        return path != null && path !== '';
    };

    const getTemplate = (item: MenuItem) => {
        const MenuText = <span className='p-menuitem-text'>{item.label}</span>;
        if (item.url) {
            return (
                <Link to={item.url} className='p-menuitem-link'>
                    {MenuText}
                </Link>
            );
        }
        return MenuText;
    };

    useEffect(() => {
        let items: MenuItem[] = [
            {
                label: 'Projects',
                url: '/projects',
            },
        ];
        const paths = location.pathname.split('/');
        if (!isAvailable(paths[2])) items[0] = { label: 'Projects' };
        else {
            if (currentProject) items.push({ label: currentProject.name });
            else {
                setBreadCrumbItems([]);
                return;
            }
        }
        if (isAvailable(paths[3])) {
            if (paths[3] === 'notices')
                items.push({
                    label: 'Notices',
                    url: isAvailable(paths[4])
                        ? `${paths[2]}/notices`
                        : undefined,
                });
            else if (paths[3] === 'noticeitems') items.push({ label: 'Items' });
            else if (paths[3] === 'counternotices')
                items.push({
                    label: 'Counter-Notices',
                    url: isAvailable(paths[4])
                        ? `${paths[2]}/counternotices`
                        : undefined,
                });
            else if (paths[3] === 'metrics')
                items.push({
                    label: 'Metrics',
                    url: isAvailable(paths[4])
                        ? `${paths[2]}/metrics`
                        : undefined,
                });
        }
        if (isAvailable(paths[4])) {
            if (paths[4] === 'noticeitem') items.push({ label: 'Item' });
            else items.push({ label: capitalizeFirstLetter(paths[4]) });
        }
        if (isAvailable(paths[5])) {
            if (paths[5] === 'noticeitems') items.push({ label: 'Items' });
            if (paths[5] === 'counterreply') items.push({ label: 'Reply' });
        }
        items = items.map((item) => ({ ...item, template: getTemplate }));
        setBreadCrumbItems(items);
    }, [location, currentProject]);

    return (
        <div>
            {!error &&
            (!params.projectOid || (params.projectOid && currentProject)) ? (
                <BreadCrumb model={breadCrumbItems} />
            ) : (
                isFetching && <LoadingSpinner />
            )}
            <ErrorBoundary
                key={location.pathname}
                FallbackComponent={ErrorFallback}
            >
                <Outlet />
            </ErrorBoundary>
        </div>
    );
};

export default Projects;
