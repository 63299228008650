import { useQuery } from '@tanstack/react-query';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Link } from 'react-router-dom';
import { countPendingLinksByProject } from 'services/nomia/general';
import { getOneProject } from 'services/nomia/project';
import { useAuth } from 'hooks/useAuth';

const Home = () => {
    const { permissions } = useAuth();

    const pendingLinksQuery = useQuery(
        ['count-pending-link-project'],
        async () => {
            const links = await countPendingLinksByProject();
            const promises = links
                .filter((link) => !!link.project_oid)
                .map(
                    (link) =>
                        new Promise<{
                            count_links: number;
                            project_oid: string;
                            project_name: string;
                        }>((resolve) => {
                            getOneProject(link.project_oid).then((project) =>
                                resolve({
                                    count_links: link.count_items_total,
                                    project_oid: link.project_oid,
                                    project_name: project.name,
                                })
                            );
                        })
                );
            return await Promise.all(promises);
        }
    );

    return (
        <div>
            <h2>Welcome to Nomia!</h2>
            <h3>Pending items by project</h3>
            <DataTable
                loading={pendingLinksQuery.status === 'loading'}
                value={pendingLinksQuery.data}
                emptyMessage='No pending items!'
                removableSort
            >
                <Column header='Project' field='project_name' sortable />
                <Column header='Count' field='count_links' sortable />
                <Column
                    header='Action'
                    body={(rowData) => {
                        const { project_oid } = rowData;
                        return (
                            permissions?.countNoticeItem &&
                            permissions?.getTitle &&
                            permissions?.getSources && (
                                <Link
                                    to={`projects/${project_oid}/notices/assign`}
                                >
                                    <Button outlined label='Assign' />
                                </Link>
                            )
                        );
                    }}
                />
            </DataTable>
        </div>
    );
};

export default Home;
