import { useState } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';

import { Button } from 'primereact/button';
import { DataTableFilterMeta } from 'primereact/datatable';

import {
    exportNoticeItems,
    listNoticeItemsByNotice,
} from 'services/nomia/noticeitem';

import NoticeItemsDataTable, {
    expectedNoticeItemsFilterValues,
} from '../../../../components/NoticeItemsDataTable';

import { useProjectQuery } from 'pages/Main/subpages/Projects/subpages/ListProjects/subpages'
import { ProgressBar } from 'primereact/progressbar';

const ListNoticeItems = () => {
    const params = useParams<{
        projectOid: string;
        noticeOid: string;
    }>() as {
        projectOid: string;
        noticeOid: string;
    };

    const [pageOptions, setPageOptions] = useState<{
        page: number;
        rows: number;
    }>({ page: 1, rows: 50 });

    const [filters, setFilters] = useState<DataTableFilterMeta>(
        expectedNoticeItemsFilterValues
    );

    const [downloadProgress, setDownloadProgress] = useState(0.0);

    const projectQuery = useProjectQuery();

    const {
        data: noticeItemsData,
        error: noticeItemsError,
        status: noticeItemsStatus,
        refetch: refetchNoticeItems,
    } = useQuery<Ether.Nomia.INoticeItem[], Error>(
        [
            'list-noticeitems',
            params.projectOid,
            params.noticeOid,
            filters,
            pageOptions,
        ],
        async ({ signal }): Promise<Ether.Nomia.INoticeItem[]> => {
            return listNoticeItemsByNotice(
                params.noticeOid,
                params.projectOid,
                {
                    signal,
                    filters,
                    limit: pageOptions.rows,
                    offset: (pageOptions.page - 1) * pageOptions.rows,
                }
            );
        }
    );

    const exportMutation = useMutation(async (): Promise<void> => {
        return exportNoticeItems(params.projectOid, {
            noticeId: params.noticeOid,
            filters,
            onCountUpdate: (count, total) => {
                if (count > total) setDownloadProgress(100);
                else
                    setDownloadProgress(
                        Math.round((count / total) * 10000) / 100
                    );
            },
        });
    });

    return (
        <div>
            <h2>
                {projectQuery?.data?.name} - {params.noticeOid} - Items
            </h2>
            <Button
                className='p-button-outlined'
                icon='pi pi-download'
                label='Export Items'
                onClick={() => exportMutation.mutate()}
                loading={exportMutation.isLoading}
            />
            {exportMutation.isLoading && (
                <ProgressBar
                    value={downloadProgress}
                    style={{ marginTop: '8px', marginBottom: '8px' }}
                />
            )}
            <NoticeItemsDataTable
                data={noticeItemsData}
                error={noticeItemsError}
                status={noticeItemsStatus}
                pageOptions={pageOptions}
                setPageOptions={setPageOptions}
                filters={filters}
                setFilters={setFilters}
                onDataUpdateNeeded={refetchNoticeItems}
            />
        </div>
    );
};

export default ListNoticeItems;
