import { useEffect, useRef, useState } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';

import { Button } from 'primereact/button';

import { getOneProject } from 'services/nomia/project';

import { Dropdown, InputNumber } from 'components/ethercity-primereact';
import { useToast } from 'hooks/useToast';
import { ToastMessage } from 'primereact/toast';
import {
    countPendingLinksBySource,
    getSources,
    getTitles,
} from 'services/nomia/general';
import { createManualNotice, getManyNotices } from 'services/nomia/notice';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Dialog } from 'primereact/dialog';
import { getMyInfo } from 'services/nomia/me';
import CacheControl from 'controller/cache/cacheController';
import { Divider } from 'primereact/divider';
import NoticesDataTable from '../../components';
import { Badge } from 'primereact/badge';

const AssignedNoticesDialog: React.FC<{
    data: Ether.Nomia.INotice[];
    refetchNotices: () => void;
    visible: boolean;
    onHide: () => void;
    projectId: string;
}> = ({ visible, onHide, projectId, data, refetchNotices }) => {
    return (
        <Dialog
            visible={visible}
            onHide={onHide}
            style={{ minWidth: '70%' }}
            header='Assigned notices'
        >
            <NoticesDataTable
                data={data}
                projectId={projectId}
                refetchNotices={refetchNotices}
                hideTestColumn
            />
            <Button
                outlined
                label='Close'
                onClick={onHide}
                style={{ marginTop: '8px' }}
            />
        </Dialog>
    );
};

const SelectTitleDialog: React.FC<{
    visible: boolean;
    onHide: () => void;
    data: {
        title: string;
        count: number;
        maxNotices: number;
    }[];
    maxNoticesTotal: number;
    groupableByTitle: boolean;
    source: string;
    sourceType: string;
    onSubmit: (data: {
        maxNotices: number;
        source: string;
        sourceType: string;
        title?: string;
    }) => void;
}> = ({
    visible,
    onHide,
    source,
    sourceType,
    maxNoticesTotal,
    groupableByTitle,
    data,
    onSubmit,
}) => {
    return (
        <Dialog
            visible={visible}
            onHide={onHide}
            style={{ minWidth: '70%' }}
            header={`Select title for ${source}/${sourceType}`}
        >
            <div>
                {!groupableByTitle && (
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <Button
                            size='large'
                            outlined
                            label='Assign any title'
                            onClick={() => {
                                onSubmit({
                                    source,
                                    sourceType,
                                    maxNotices: maxNoticesTotal,
                                });
                            }}
                            style={{ marginTop: '8px' }}
                        />
                        <Divider align='center'>
                            <Badge value='OR' />
                        </Divider>
                    </div>
                )}
            </div>
            <DataTable value={data}>
                <Column header='Title' field='title' sortable />
                <Column header='Links count' field='count' sortable />
                <Column
                    header='Action'
                    body={(rowData: { title: string; maxNotices: number }) => {
                        return (
                            <Button
                                outlined
                                label='Assign'
                                onClick={() => {
                                    onSubmit({
                                        source,
                                        sourceType,
                                        title: rowData.title,
                                        maxNotices: rowData.maxNotices,
                                    });
                                }}
                            />
                        );
                    }}
                />
            </DataTable>
        </Dialog>
    );
};

const CreateManualNotice = () => {
    const toast = useToast();
    const maxNoticesByGroupRef = useRef<HTMLInputElement>(null);

    const params = useParams<{
        projectOid: string;
    }>() as {
        projectOid: string;
    };

    const { data: myInfo } = useQuery(['my-info'], () => getMyInfo());

    const [selectedSource, setSelectedSource] = useState<string | null>(null);
    const [selectedSourceType, setSelectedSourceType] = useState<string | null>(
        null
    );
    const [selectedTitleSlug, setSelectedTitleSlug] = useState<string | null>(
        null
    );
    const [maxNumberOfNotices, setMaxNumberOfNotices] = useState(1);

    const [selectedInfo, setSelectedInfo] = useState<null | {
        source: string;
        sourceType: string;
        maxNoticesTotal: number;
        isGroupable: boolean;
        data: {
            title: string;
            count: number;
            maxNotices: number;
        }[];
    }>(null);
    const [createdNotices, setCreatedNotices] = useState<string[]>([]);
    const [isNoticeDialogVisible, setIsNoticeDialogVisible] = useState(false);

    const [sort, setSort] = useState({
        field: 'source',
        order: 1 as 0 | -1 | 1 | undefined | null,
    });

    useEffect(() => {
        if (!myInfo) return;
        setCreatedNotices(CacheControl.AssignedNotices.get(myInfo._id) ?? []);
    }, [myInfo]);

    const {
        data: projectData,
        error: projectError,
        status: projectStatus,
    } = useQuery<Ether.Nomia.IProject, Error>(
        ['get-one-project', params.projectOid],
        async ({ signal }): Promise<Ether.Nomia.IProject> => {
            return getOneProject(params.projectOid, { signal });
        }
    );

    const {
        data: sourcesData,
        status: sourcesStatus,
        error: sourcesError,
    } = useQuery<Ether.Nomia.ISource[], Error>(
        ['get-sources'],
        async ({ signal }) => getSources({ signal })
    );

    const {
        data: titlesData,
        status: titlesStatus,
        error: titlesError,
    } = useQuery<Ether.Nomia.ITitle[], Error>(
        ['get-titles'],
        async ({ signal }) => getTitles({ signal })
    );

    const {
        data: pendingLinksCount,
        status: pendingLinksStatus,
        error: pendingLinksError,
        refetch: refetchPendingLinks,
    } = useQuery<Ether.Nomia.IPendingLinkBySource[], Error>(
        ['count-pending-noticeitems-by-source', params.projectOid],
        async ({ signal }) =>
            countPendingLinksBySource(params.projectOid, { signal })
    );

    const {
        data: noticesData,
        status: noticesStatus,
        refetch: refetchNotices,
    } = useQuery<Ether.Nomia.INotice[], Error>(
        ['list-notices-by-id', createdNotices],
        async ({ signal }): Promise<Ether.Nomia.INotice[]> => {
            if (!params.projectOid) throw Error('projectOid not defined');
            if (createdNotices.length === 0) throw Error('No created notices');
            return getManyNotices(createdNotices, params.projectOid, {
                signal,
            });
        },
        {
            enabled: createdNotices.length > 0,
        }
    );

    useEffect(() => {
        if (!noticesData) return;
        const validNotices: string[] = [];
        noticesData.forEach((notice) => {
            if (notice.status !== 'queued' && notice.status !== 'new') return;
            validNotices.push(notice._id);
        });
        setCreatedNotices(validNotices);
        if (myInfo) CacheControl.AssignedNotices.save(myInfo._id, validNotices);
    }, [noticesData, myInfo]);

    const createNoticeMutation = useMutation<
        {
            notice_number: number;
            created: boolean;
            error?: string;
            notice_id?: string;
        }[],
        Error,
        {
            source: string;
            sourceType: string;
            maxNumberOfNotices: number;
            titleSlug?: string;
        }
    >(
        async ({ source, sourceType, maxNumberOfNotices, titleSlug }) => {
            if (!projectData) throw new Error('data missing');
            return createManualNotice({
                project_oid: projectData._id,
                source: source,
                source_type: sourceType,
                title_slug: titleSlug,
                max_notices: maxNumberOfNotices,
            });
        },
        {
            onSuccess: (notices) => {
                const errorNotices = notices.filter((i) => !!i.error);
                const maxDisplay = 10;

                let summary = `Assigned ${notices.length} notices`;
                let detail = null;
                let severity: ToastMessage['severity'] = 'success';
                if (errorNotices.length > 0) {
                    detail = (
                        <div
                            style={{ display: 'flex', flexDirection: 'column' }}
                        >
                            {errorNotices
                                .slice(0, maxDisplay + 1)
                                .map((notice, index) =>
                                    index < maxDisplay ? (
                                        <span key={notice.notice_number}>
                                            Notice{' '}
                                            {notice.notice_number != null
                                                ? notice.notice_number + 1
                                                : ''}{' '}
                                            - {notice.error}
                                        </span>
                                    ) : (
                                        <span key={notice.notice_number}>
                                            And{' '}
                                            {errorNotices.length - maxDisplay}{' '}
                                            more
                                        </span>
                                    )
                                )}
                        </div>
                    );
                    if (errorNotices.length === notices.length) {
                        summary = 'Failed to assign notices';
                        severity = 'error';
                    } else {
                        summary = 'Partially assigned notices';
                        severity = 'warn';
                    }
                }
                const toastData: ToastMessage = {
                    severity,
                    summary,
                    detail,
                    life: 10000,
                };
                toast?.show(toastData);

                setCreatedNotices((old) => {
                    const moreNotices = notices
                        .filter((n) => !!n.notice_id)
                        .map((n) => n.notice_id) as string[];
                    const newData = [...old, ...moreNotices];
                    if (myInfo)
                        CacheControl.AssignedNotices.save(
                            myInfo._id,
                            createdNotices
                        );
                    return newData;
                });
            },
            onError: (err) => {
                toast?.show({
                    severity: 'error',
                    summary: 'Failed to assign notice',
                    detail: err.message,
                });
            },
            onSettled: () => refetchPendingLinks(),
        }
    );

    const confirmHandleAssign = ({
        maxNotices,
        source,
        sourceType,
        title,
    }: {
        maxNotices: number;
        source: string;
        sourceType: string;
        title?: string;
    }) => {
        confirmDialog({
            message: (
                <>
                    <p>Do you want to assign this as a notice?</p>
                    <p>
                        {source}/{sourceType}
                    </p>
                    {title && <p>Title: {title}</p>}
                    <InputNumber
                        inputRef={maxNoticesByGroupRef}
                        value={
                            maxNoticesByGroupRef.current?.value
                                ? Number(maxNoticesByGroupRef.current.value)
                                : 1
                        }
                        label={`Max notices (up to ${maxNotices})`}
                        max={maxNotices}
                        min={1}
                        onBlur={(e) => {
                            if (e.target.value === '') e.target.value = '1';
                        }}
                        disabled={maxNotices === 1}
                        useGrouping={false}
                    />
                </>
            ),
            header: 'Confirm assign',
            icon: 'pi pi-info-circle',
            acceptClassName: 'p-button-success',
            accept: () => {
                const { value } = maxNoticesByGroupRef.current ?? {};
                const maxNotices = value ? Number(value) : 1;
                createNoticeMutation.mutate({
                    source: source,
                    sourceType: sourceType,
                    maxNumberOfNotices: maxNotices,
                    titleSlug: title,
                });
                setSelectedInfo(null);
            },
        });
    };

    return (
        <div>
            <ConfirmDialog />
            {selectedInfo && (
                <SelectTitleDialog
                    visible={!!selectedInfo}
                    onHide={() => setSelectedInfo(null)}
                    source={selectedInfo.source}
                    sourceType={selectedInfo.sourceType}
                    data={selectedInfo.data}
                    onSubmit={confirmHandleAssign}
                    maxNoticesTotal={selectedInfo.maxNoticesTotal}
                    groupableByTitle={selectedInfo.isGroupable}
                />
            )}
            <AssignedNoticesDialog
                data={noticesData ?? []}
                visible={isNoticeDialogVisible}
                onHide={() => setIsNoticeDialogVisible(false)}
                projectId={params.projectOid}
                refetchNotices={refetchNotices}
            />
            {projectStatus === 'loading' ? (
                <h2>Loading project info...</h2>
            ) : projectError ? (
                <h2>Error getting project: {projectError.toString()}</h2>
            ) : !projectData ? (
                <h2>Project not found</h2>
            ) : (
                <>
                    <h2>{projectData.name} - Assign notice</h2>
                    {createdNotices.length > 0 && (
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                marginBottom: '16px',
                            }}
                        >
                            <Button
                                outlined
                                label={'See assigned'}
                                onClick={() => setIsNoticeDialogVisible(true)}
                                loading={noticesStatus === 'loading'}
                            />
                        </div>
                    )}
                    <div
                        style={{
                            display: 'grid',
                            gridTemplateColumns: 'repeat(3, 1fr)',
                            gap: '8px',
                        }}
                    >
                        <Dropdown
                            label='Source'
                            value={selectedSource}
                            onChange={(e) => {
                                const { value } = e.target;
                                setSelectedSource(value);
                                const sourceType = sourcesData?.find(
                                    (i) => i.source === value
                                )?.source_type;
                                if (sourceType)
                                    setSelectedSourceType(sourceType);
                            }}
                            options={sourcesData
                                ?.map((item) => ({
                                    label: `${item.source} / ${item.source_type}`,
                                    value: item.source,
                                }))
                                .sort((a, b) =>
                                    a.value > b.value
                                        ? 1
                                        : a.value < b.value
                                        ? -1
                                        : 0
                                )}
                            loading={sourcesStatus === 'loading'}
                            disabled={sourcesStatus !== 'success'}
                            placeholder={
                                !!sourcesError
                                    ? 'Failed to get sources'
                                    : 'Select source'
                            }
                            style={{ width: '100%' }}
                            required
                        />
                        <Dropdown
                            label='Title slug'
                            showClear
                            value={selectedTitleSlug}
                            onChange={(e) =>
                                setSelectedTitleSlug(e.target.value)
                            }
                            options={titlesData
                                ?.map((item) => item.titledb_slug)
                                .sort((a, b) => (a > b ? 1 : a < b ? -1 : 0))}
                            loading={titlesStatus === 'loading'}
                            disabled={titlesStatus !== 'success'}
                            placeholder={
                                !!titlesError
                                    ? 'Failed to get titles'
                                    : 'Select title'
                            }
                            style={{ width: '100%' }}
                            required={false}
                        />
                        <InputNumber
                            label='Max number of notices'
                            value={maxNumberOfNotices ?? 0}
                            onChange={(e) =>
                                setMaxNumberOfNotices(e.value ?? 0)
                            }
                            useGrouping={false}
                            allowEmpty={false}
                        />
                        <Button
                            className='p-button-outlined'
                            icon='pi pi-plus'
                            label='Assign Notice'
                            onClick={() => {
                                if (!selectedSource || !selectedSourceType)
                                    return;
                                createNoticeMutation.mutate({
                                    source: selectedSource,
                                    sourceType: selectedSourceType,
                                    maxNumberOfNotices: maxNumberOfNotices,
                                    titleSlug: selectedTitleSlug ?? undefined,
                                });
                            }}
                            loading={createNoticeMutation.isLoading}
                            disabled={
                                selectedSource == null ||
                                selectedSourceType == null
                            }
                            style={{
                                gridColumn: 'span 3',
                            }}
                        />
                    </div>
                    <h3>Pending items</h3>
                    {pendingLinksError ? (
                        <p>Error getting links</p>
                    ) : (
                        <DataTable
                            loading={pendingLinksStatus === 'loading'}
                            value={pendingLinksCount}
                            emptyMessage='No pending items!'
                            removableSort
                            sortField={sort.field}
                            sortOrder={sort.order}
                            onSort={(e) => {
                                setSort({
                                    field: e.sortField,
                                    order: e.sortOrder,
                                });
                            }}
                        >
                            <Column header='Source' field='source' sortable />
                            <Column
                                header='Type'
                                field='source_type'
                                sortable
                            />
                            <Column
                                header='Count'
                                field='count_items_total'
                                sortable
                            />
                            <Column
                                header='Type'
                                field='worker_type'
                                sortable
                            />
                            <Column
                                header='Action'
                                body={(
                                    rowData: Ether.Nomia.IPendingLinkBySource
                                ) => {
                                    const hasTitle =
                                        rowData.groupable_by_title ||
                                        rowData.options.some(
                                            (item) => item.meta_title != null
                                        );
                                    return (
                                        <Button
                                            outlined
                                            loading={
                                                createNoticeMutation.isLoading
                                            }
                                            label={
                                                hasTitle
                                                    ? 'Select title'
                                                    : 'Assign'
                                            }
                                            onClick={() => {
                                                if (hasTitle) {
                                                    setSelectedInfo({
                                                        data: rowData.options
                                                            .filter(
                                                                (item) =>
                                                                    item.meta_title !=
                                                                    null
                                                            )
                                                            .map((item) => ({
                                                                count: item.count_items,
                                                                maxNotices:
                                                                    item.max_notices,
                                                                title: item.meta_title,
                                                            })),
                                                        source: rowData.source,
                                                        sourceType:
                                                            rowData.source_type,
                                                        maxNoticesTotal:
                                                            rowData.max_notices_total,
                                                        isGroupable:
                                                            rowData.groupable_by_title,
                                                    });
                                                } else {
                                                    confirmHandleAssign({
                                                        source: rowData.source,
                                                        sourceType:
                                                            rowData.source_type,
                                                        maxNotices:
                                                            rowData.max_notices_total,
                                                    });
                                                }
                                            }}
                                        />
                                    );
                                }}
                            />
                        </DataTable>
                    )}
                </>
            )}
        </div>
    );
};

export default CreateManualNotice;
