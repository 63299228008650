import React, { useState } from 'react';
import { formatDistanceToNowStrict } from 'date-fns';
import { useMutation, useQuery } from '@tanstack/react-query';

import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { Badge, BadgeProps } from 'primereact/badge';
import { Column, ColumnFilterElementTemplateOptions } from 'primereact/column';
import { DataTable, DataTableFilterMeta } from 'primereact/datatable';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { Tooltip } from 'primereact/tooltip';
import { Sidebar } from 'primereact/sidebar';

import {
    InputText,
    ObjectDisplayModal,
    Paginator,
} from 'components/ethercity-primereact';

import { renotifyNoticeItems } from 'services/nomia/noticeitem';

import './style.css';
import { useToast } from 'hooks/useToast';
import { getOneNotice } from 'services/nomia/notice';
import { useParams } from 'react-router-dom';
import OidColumn from 'components/Misc/OidColumn';

interface INoticeItemsDataTableProps {
    data?: Ether.Nomia.INoticeItem[];
    error: Error | null;
    status: string;

    pageOptions: { page: number; rows: number };
    setPageOptions: React.Dispatch<
        React.SetStateAction<{
            page: number;
            rows: number;
        }>
    >;

    filters?: DataTableFilterMeta;
    setFilters?: React.Dispatch<React.SetStateAction<DataTableFilterMeta>>;

    onDataUpdateNeeded(): void;
}

export const expectedNoticeItemsFilterValues = {
    _id: {
        value: null,
        matchMode: FilterMatchMode.EQUALS,
    },
    notice_status: {
        value: null,
        matchMode: FilterMatchMode.IN,
    },
    'onoff_data.is_active': {
        value: null,
        matchMode: FilterMatchMode.IN,
    },
    'last_notice.status': {
        value: null,
        matchMode: FilterMatchMode.IN,
    },
    key: {
        operator: FilterOperator.AND,
        constraints: [
            {
                value: null,
                matchMode: FilterMatchMode.STARTS_WITH,
            },
        ],
    },
    source: {
        operator: FilterOperator.AND,
        constraints: [
            {
                value: null,
                matchMode: FilterMatchMode.EQUALS,
            },
        ],
    },
};

const NoticeItemsDataTable: React.FC<INoticeItemsDataTableProps> = ({
    data,
    error,
    status,

    pageOptions,
    setPageOptions,

    filters,
    setFilters,

    onDataUpdateNeeded,
}) => {
    const toast = useToast();
    const params = useParams<{ projectOid: string }>() as {
        projectOid: string;
    };

    const [selectedNoticeItem, setSelectedNoticeItem] =
        useState<Ether.Nomia.INoticeItem | null>(null);
    const [selectedNoticeId, setSelectedNoticeId] = useState<string | null>(
        null
    );
    const [selectedItems, setSelectedItems] = useState<
        Ether.Nomia.INoticeItem[]
    >([]);

    const noticeQuery = useQuery<Ether.Nomia.INotice, Error>(
        ['get-one-notice', selectedNoticeId],
        async ({ signal }): Promise<Ether.Nomia.INotice> => {
            if (!selectedNoticeId) throw Error('unreachable');
            return getOneNotice(selectedNoticeId, params.projectOid, {
                signal,
            });
        },
        {
            enabled: !!selectedNoticeId,
        }
    );

    const renotifyMutation = useMutation(
        async (ids: string[]) => {
            if (ids.length === 0) return;

            const result = await renotifyNoticeItems(ids);
            const messages: string[] = [];
            let severity: 'success' | 'info' | 'error';

            if (result.updated > 0) messages.push(`Updated ${result.updated}.`);
            if (result.already_pending)
                messages.push(
                    `${result.already_pending} ${
                        result.already_pending > 1 ? 'items' : 'item'
                    } already pending.`
                );
            if (result.errors.length > 0)
                messages.push(`${result.errors.length} errors.`);

            if (result.updated > 0 && result.errors.length === 0)
                severity = 'success';
            else if (result.errors.length > 0 && result.updated === 0)
                severity = 'error';
            else severity = 'info';

            toast?.show({
                severity,
                summary: messages.join(','),
            });

            setSelectedItems([]);
            onDataUpdateNeeded();
        },
        {
            onError: (err: Error) => {
                toast?.show({
                    severity: 'error',
                    summary: err.message,
                });
            },
        }
    );

    const renderUrl = (rowData: Ether.Nomia.INoticeItem) => {
        const maxLen = 120;
        const { value } = rowData;
        let trimmedValue = value;
        if (trimmedValue.length > maxLen) {
            trimmedValue =
                value.substring(0, maxLen / 2 - 3) +
                ' [...] ' +
                value.substring(value.length - maxLen / 2 + 3, value.length);
        }
        if (!value) return '-';
        return (
            <a
                href={value}
                target='_blank'
                rel='noreferrer'
                className='hoverable-link'
            >
                {trimmedValue}
            </a>
        );
    };

    const renderNoticeStatus = (rowData: Ether.Nomia.INoticeItem) => {
        const status = rowData.notice_status;
        const severity: BadgeProps['severity'] = (
            status === 'pending' ? 'warning' : 'success'
        ) as BadgeProps['severity'];
        return <Badge value={status} severity={severity} />;
    };

    const renderOnOffIsActive = (rowData: Ether.Nomia.INoticeItem) => {
        const { onoff_data: onoffData } = rowData;
        const active = rowData.onoff_data.is_active;

        const tooltipMessage = [];
        if (onoffData.first_on_captured_at) {
            tooltipMessage.push(
                `Online at ${
                    onoffData.first_on_captured_at.toISOString().split('T')[0]
                }`
            );
        }
        if (onoffData.first_off_captured_at) {
            tooltipMessage.push(
                `Offline at ${
                    onoffData.first_off_captured_at.toISOString().split('T')[0]
                }`
            );
        }

        return (
            <span
                className='onoff-badge'
                data-pr-position='left'
                data-pr-tooltip={tooltipMessage.join(' - ')}
                style={{
                    cursor: 'pointer',
                }}
                title={tooltipMessage.join(' - ')}
            >
                <Badge
                    value={
                        active
                            ? 'Online'
                            : active === false
                            ? 'Offline'
                            : 'unknown'
                    }
                    severity={
                        active
                            ? 'danger'
                            : active === false
                            ? 'success'
                            : 'warning'
                    }
                />
            </span>
        );
    };

    const renderStatus = (rowData: Ether.Nomia.INoticeItem) => {
        const status = rowData.last_notice?.status;
        if (!status) return '-';

        let severity: BadgeProps['severity'] = 'info';

        if (status === 'queued') {
            severity = 'warning';
        } else if (status === 'error') {
            severity = 'danger';
        } else if (status === 'assigned') {
            severity = 'success';
        }

        return <Badge value={status} severity={severity} />;
    };

    const renderDateOffset = (rowData: Ether.Nomia.INoticeItem) => {
        const sentAt = rowData.last_notice?.sent_at;
        if (!sentAt) return '-';
        return (
            <div
                className='onoff-badge'
                data-pr-position='left'
                data-pr-tooltip={sentAt.toISOString()}
                style={{
                    cursor: 'pointer',
                }}
                title={sentAt.toISOString()}
            >
                {formatDistanceToNowStrict(sentAt, {
                    addSuffix: true,
                })}
            </div>
        );
    };

    const sourceFilterTemplate = (
        options: ColumnFilterElementTemplateOptions
    ) => {
        return (
            <InputText
                value={options.value ?? ''}
                onChange={(e) =>
                    options.filterCallback(e.target.value, options.index)
                }
                className='p-column-filter'
            />
        );
    };

    const noticeStatusFilterTemplate = (
        options: ColumnFilterElementTemplateOptions
    ) => {
        return (
            <Dropdown
                value={options.value}
                options={[
                    { label: 'Pending', value: 'pending' },
                    { label: 'Assigned', value: 'assigned' },
                ]}
                onChange={(e) => options.filterCallback(e.value, options.index)}
                placeholder='Select status'
                className='p-column-filter'
            />
        );
    };

    const lastNoticeStatusFilterTemplate = (
        options: ColumnFilterElementTemplateOptions
    ) => {
        return (
            <Dropdown
                value={options.value}
                options={[
                    { label: 'New', value: 'new' },
                    { label: 'Queued', value: 'queued' },
                    { label: 'Sent', value: 'sent' },
                    { label: 'Assigned', value: 'assigned' },
                    { label: 'Error', value: 'error' },
                ]}
                onChange={(e) => options.filterCallback(e.value, options.index)}
                placeholder='Select status'
                className='p-column-filter'
            />
        );
    };

    const onOffStatusFilterTemplate = (
        options: ColumnFilterElementTemplateOptions
    ) => {
        return (
            <Dropdown
                value={options.value}
                options={[
                    { label: 'Online', value: 'online' },
                    { label: 'Offline', value: 'offline' },
                    { label: 'Unknown', value: 'null' },
                    { label: 'Valid', value: 'not_null' },
                ]}
                onChange={(e) => options.filterCallback(e.value, options.index)}
                placeholder='Select status'
                className='p-column-filter'
            />
        );
    };

    return error ? (
        <div>{error.toString()}</div>
    ) : (
        <>
            <Tooltip target='.onoff-badge' autoHide={false} />
            <ObjectDisplayModal
                displayData={selectedNoticeItem}
                header={selectedNoticeItem?.key}
                visible={!!selectedNoticeItem}
                onHide={() => setSelectedNoticeItem(null)}
            />
            <ObjectDisplayModal
                displayData={noticeQuery.data}
                header={`Notice ${selectedNoticeId}`}
                visible={!!selectedNoticeId && !!noticeQuery.data}
                onHide={() => setSelectedNoticeId(null)}
            />
            <Sidebar
                visible={selectedItems.length > 0}
                modal={false}
                position='bottom'
                blockScroll={false}
                dismissable={false}
                onHide={() => setSelectedItems([])}
                style={{ height: '52px', paddingTop: '8px' }}
            >
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '8px',
                        overflow: 'hidden',
                        flexDirection: 'row-reverse',
                        paddingRight: '40px',
                    }}
                >
                    <span>{`${selectedItems.length} ${
                        selectedItems.length > 1 ? 'items' : 'item'
                    } selected`}</span>
                    <Button
                        label='Renotify items'
                        className='p-button-outlined'
                        onClick={() =>
                            renotifyMutation.mutate(
                                selectedItems.map((a) => a._id)
                            )
                        }
                        loading={renotifyMutation.isLoading}
                    />
                </div>
            </Sidebar>

            <Paginator
                disableNext={!data || data.length < pageOptions.rows}
                page={pageOptions.page}
                rows={pageOptions.rows}
                onPageChange={setPageOptions}
            />
            <DataTable
                value={data}
                lazy={true}
                loading={status === 'loading'}
                emptyMessage='No items available'
                filters={filters}
                onFilter={(e) => {
                    if (setFilters) setFilters(e.filters);
                }}
                rowHover
                metaKeySelection={false}
                selectionMode='multiple'
                selection={selectedItems}
                onSelectionChange={(e) =>
                    setSelectedItems(e.value as Ether.Nomia.INoticeItem[])
                }
                selectAll={false}
            >
                <Column
                    selectionMode='multiple'
                    headerStyle={{ visibility: 'hidden' }}
                />
                {OidColumn()}
                <Column
                    field='source'
                    header='Source'
                    filter
                    filterElement={sourceFilterTemplate}
                    showAddButton={false}
                    showFilterOperator={false}
                />
                <Column field='value' header='URL' body={renderUrl} />
                <Column
                    field='notice_status'
                    header='Status'
                    body={renderNoticeStatus}
                    filter
                    filterElement={noticeStatusFilterTemplate}
                    showFilterMatchModes={false}
                    showFilterOperator={false}
                    showFilterMenuOptions={false}
                />
                <Column
                    field='onoff_data.is_active'
                    header='OnOff Status'
                    body={renderOnOffIsActive}
                    filter
                    filterElement={onOffStatusFilterTemplate}
                    showFilterMatchModes={false}
                    showFilterOperator={false}
                    showFilterMenuOptions={false}
                />
                <Column
                    field='last_notice.status'
                    header='Notice Status'
                    body={renderStatus}
                    filter
                    filterElement={lastNoticeStatusFilterTemplate}
                    showFilterMatchModes={false}
                    showFilterOperator={false}
                    showFilterMenuOptions={false}
                />
                <Column
                    field='last_notice.sent_at'
                    header='Last sent at'
                    body={renderDateOffset}
                    // filter
                    // filterElement={onOffStatusFilterTemplate}
                    // showFilterMatchModes={false}
                    // showFilterOperator={false}
                    // showFilterMenuOptions={false}
                />
                <Column
                    field='details'
                    header='Details'
                    body={(rowData: Ether.Nomia.INoticeItem) => (
                        <Button
                            icon='pi pi-search'
                            className='p-button-outlined'
                            onClick={() => setSelectedNoticeItem(rowData)}
                        />
                    )}
                />
                <Column
                    field='last_notice'
                    header='Last notice actions'
                    body={(rowData: Ether.Nomia.INoticeItem) => {
                        if (!rowData.last_notice?.notice_id)
                            return (
                                <Button
                                    icon='pi pi-search'
                                    className='p-button-outlined'
                                    disabled
                                />
                            );
                        const id = rowData.last_notice.notice_id;
                        return (
                            <Button
                                icon='pi pi-search'
                                className='p-button-outlined'
                                onClick={() => setSelectedNoticeId(id)}
                                loading={noticeQuery.isFetching}
                            />
                        );
                    }}
                />
                {/* <Column header='Actions' body={renderActionButtons} /> */}
            </DataTable>
            {selectedItems.length > 0 && <div style={{ height: '200px' }} />}
        </>
    );
};

export default NoticeItemsDataTable;
