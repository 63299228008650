import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { createContext, useContext } from 'react';
import { useErrorBoundary } from 'react-error-boundary';
import { Outlet, useParams } from 'react-router-dom';
import { getOneProject } from 'services/nomia/project';

const ProjectContext = createContext<UseQueryResult<
    Ether.Nomia.IProject,
    Error
> | null>(null);

const ProjectSubpages = () => {
    const errorBoundary = useErrorBoundary();

    const params = useParams<{
        projectOid: string;
    }>() as { projectOid: string };

    const projectQuery = useQuery<Ether.Nomia.IProject, Error>(
        ['get-one-project', params.projectOid],
        async ({ signal }): Promise<Ether.Nomia.IProject> =>
            getOneProject(params.projectOid, { signal }),
        {
            onSuccess: (data) => {
                if (data === null) errorBoundary.showBoundary(new Error('Project not found'));
            },
            onError: (err) => errorBoundary.showBoundary(err),
        }
    );

    return projectQuery.status === 'loading' ? (
        <h2>Loading project info...</h2>
    ) : (
        <ProjectContext.Provider value={projectQuery}>
            <Outlet />
        </ProjectContext.Provider>
    );
};

export const useProjectQuery = () => {
    const context = useContext(ProjectContext);
    return context;
};

export default ProjectSubpages;
