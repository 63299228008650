import { Column } from 'primereact/column';
import OidFilter from '../OidFilter';
import { OidBadge } from 'components/ethercity-primereact';

const OidColumn = (options?: { hideFilter?: boolean; sortable?: boolean }) => {
    return (
        <Column
            field='_id'
            header='OID'
            filter={!options?.hideFilter}
            filterElement={OidFilter}
            showAddButton={false}
            showFilterOperator={false}
            showFilterMatchModes={false}
            body={(rowData) => <OidBadge value={rowData._id} />}
            sortable={options?.sortable}
        />
    );
};

export default OidColumn;
