import { useParams } from 'react-router-dom';
import { countNotice } from 'services/nomia/notice';
import { useProjectQuery } from '../../..';
import GenericCountScreen from '../../components/GenericCountScreen';

const NoticeMetrics = () => {
    const params = useParams<{
        projectOid: string;
    }>() as { projectOid: string };

    const projectQuery = useProjectQuery();

    return (
        <GenericCountScreen
            cacheKey='notice'
            title='Notice count'
            groupByOptions={[
                {
                    label: 'Status',
                    value: 'status',
                },
                {
                    label: 'Source',
                    value: 'target.source',
                    field: 'target_source',
                },
            ]}
            fetchDataCountFn={(options) =>
                countNotice({
                    ...options,
                    filters: {
                        ...options?.filters,
                        project_oid: params.projectOid,
                    },
                })
            }
            filterHandlers={[
                {
                    defaultValue: [],
                    key: 'target.source|in',
                    handler: (v: string[]) => {
                        if (v.length > 0) return v.join(',');
                        return null;
                    },
                    type: 'chips',
                    label: 'Sources',
                },
                {
                    defaultValue: [],
                    key: 'target.source_type|in',
                    handler: (v: string[]) => {
                        if (v.length > 0) return v.join(',');
                        return null;
                    },
                    type: 'chips',
                    label: 'Source types',
                },
            ]}
            countColumnOptions={{
                header: 'Notices',
            }}
            exportOptions={{
                filename: `nomia_${projectQuery?.data?.name
                    .toLocaleLowerCase()
                    .replace(' ', '-')}_notice_count`,
            }}
        />
    );
};

export default NoticeMetrics;
