import { ProgressSpinner } from 'primereact/progressspinner';
import {
    MultiSelect as PrimeReactMultiSelect,
    MultiSelectProps as PrimeReactMultiSelectProps,
} from 'primereact/multiselect';

import './style.css';
import renderIsRequired from '../utils/renderIsRequired';

interface IMultiSelect extends PrimeReactMultiSelectProps {
    label?: string;
    labelStyle?: React.CSSProperties;
    loading?: boolean;
    error?: string;
    required?: boolean;
    wrapperStyle?: React.CSSProperties;
}

export const MultiSelect: React.FC<IMultiSelect> = ({
    label,
    name,
    labelStyle,
    loading = false,
    error,
    required,
    ...rest
}) => {
    return (
        <div className='custom-multiselect' style={rest.wrapperStyle}>
            <span>
                <label
                    htmlFor={name}
                    style={{
                        marginRight: '8px',
                        display: label ? 'inherit' : 'none',
                        ...labelStyle,
                    }}
                >
                    {label}
                    {renderIsRequired(required)}
                </label>
                <div className='wrap-content'>
                    <PrimeReactMultiSelect
                        className={
                            rest.className + (error ? '  p-invalid' : '')
                        }
                        disabled={loading || rest.disabled}
                        showSelectAll={rest.showSelectAll ?? false}
                        showClear={rest.showClear ?? true}
                        {...rest}
                    />
                    {loading && (
                        <ProgressSpinner
                            style={{
                                width: '20px',
                                height: '20px',
                                margin: '0 0',
                            }}
                            strokeWidth='6'
                            className='custom-spinner'
                        />
                    )}
                </div>
                {error && (
                    <small
                        id={`${name}-help`}
                        className='p-error block multiselect-error'
                    >
                        {error}
                    </small>
                )}
            </span>
        </div>
    );
};
