import React, { useRef } from 'react';

import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';

import './style.css';

interface IOidBadge {
    value: string;
}

export const OidBadge: React.FC<IOidBadge> = ({ value }) => {
    const toastRef = useRef<Toast>(null);

    return (
        <>
            <Toast ref={toastRef} />
            <Button
                className='oid-badge p-button-rounded p-button-success'
                label={`${value.substring(0, 4)} ... ${value.substring(
                    value.length - 4
                )}`}
                style={{width: '108px'}}
                tooltip={`Copy (${value})`}
                onClick={() =>
                    navigator.clipboard.writeText(value).then(() =>
                        toastRef?.current?.show({
                            severity: 'success',
                            summary: 'Copied',
                            detail: 'OID copied to clipboard',
                            life: 3000,
                        })
                    )
                }
            />
        </>
    );
};
