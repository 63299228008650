import { useMutation, useQueryClient } from '@tanstack/react-query';
import OidColumn from 'components/Misc/OidColumn';
import {
    DateBadge,
    Datepicker,
    Dropdown,
    InputText,
    ObjectDisplayModal,
} from 'components/ethercity-primereact';
import { useToast } from 'hooks/useToast';
import { Badge, BadgeProps } from 'primereact/badge';
import { Button } from 'primereact/button';
import { Column, ColumnFilterElementTemplateOptions } from 'primereact/column';
import {
    DataTable,
    DataTableFilterMeta,
    DataTableStateEvent,
} from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { ProgressBar } from 'primereact/progressbar';
import { Tooltip } from 'primereact/tooltip';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { finishNoticeJob } from 'services/nomia/notice';
import {
    exportNoticeItems,
    listNoticeItemsByNotice,
} from 'services/nomia/noticeitem';

const NoticesDataTable: React.FC<{
    data?: Ether.Nomia.INotice[];
    projectId: string;
    isLazy?: boolean;
    loading?: boolean;
    filterOptions?: {
        onFilter?: (event: DataTableStateEvent) => void;
        filters?: DataTableFilterMeta;
        hideFilters?: boolean;
    };
    sortOptions?: {
        onSort?: (event: DataTableStateEvent) => void;
        sortOrder?: 0 | 1 | -1 | null;
        sortField?: string;
    };
    hideTestColumn?: boolean;
    refetchNotices?: () => void;
}> = ({
    data,
    projectId,
    isLazy,
    loading,
    filterOptions,
    sortOptions,
    hideTestColumn,
    refetchNotices,
}) => {
    const toast = useToast();
    const queryClient = useQueryClient();

    const [selectedNotice, setSelectedNotice] =
        useState<Ether.Nomia.INotice | null>(null);
    const [hoveredNotice, setHoveredNotice] =
        useState<Ether.Nomia.INotice | null>(null);
    const [noticeFinishTarget, setNoticeFinishTarget] =
        useState<Ether.Nomia.INotice | null>(null);
    const [dialogFinishFormData, setDialogFinishFormData] = useState<{
        sentAt: Date | null;
        sentBy: string;
    }>({
        sentAt: null,
        sentBy: '',
    });
    const [currentDownloadingNoticeId, setCurrentDownloadingNoticeId] =
        useState<string | null>(null);
    const [downloadProgress, setDownloadProgress] = useState(0.0);

    const exportNoticeItemsMutation = useMutation(
        async (noticeId: string): Promise<void> => {
            setCurrentDownloadingNoticeId(noticeId);
            exportNoticeItems(projectId, {
                noticeId: noticeId,
                onCountUpdate: (count, total) => {
                    if (count > total) setDownloadProgress(100);
                    else
                        setDownloadProgress(
                            Math.round((count / total) * 10000) / 100
                        );
                },
            });
        },
        {
            onSettled: () => setCurrentDownloadingNoticeId(null),
        }
    );

    const finishNoticeMutation = useMutation(
        (params: {
            noticeId: string;
            data: {
                sent_at: Date;
                sent_info: string;
            };
        }): Promise<Ether.Nomia.INotice> => {
            return finishNoticeJob(params.noticeId, {
                ...params.data,
            });
        },
        {
            onSuccess: () => {
                if (refetchNotices) refetchNotices();
                handleCloseFinishNoticeDialog();
                toast?.show({
                    summary: 'Notice sent',
                    severity: 'success',
                    life: 5000,
                });
            },
            onError: (err: any) => {
                toast?.show({
                    summary: 'Failed to send',
                    severity: 'error',
                    detail: err.toString(),
                    life: 10000,
                });
            },
        }
    );

    const copyNoticeItemsToClipboardMutation = useMutation(
        async (noticeId: string) => {
            setCurrentDownloadingNoticeId(noticeId);
            const noticeItems = await queryClient.fetchQuery(
                ['list-noticeitems', projectId, noticeId],
                () =>
                    listNoticeItemsByNotice(noticeId, projectId, {
                        limit: 10000,
                        fields: ['value'],
                    })
            );
            if (noticeItems.length === 0) {
                toast?.show({
                    severity: 'error',
                    summary: 'Notice has no items',
                });
                return;
            }
            const clipboardContent = noticeItems.map((n) => n.value).join('\n');
            navigator.clipboard.writeText(clipboardContent).then(() =>
                toast?.show({
                    severity: 'success',
                    summary: 'Items copied to clipboard',
                    life: 3000,
                })
            );
        },
        {
            onSettled: () => setCurrentDownloadingNoticeId(null),
        }
    );

    const handleCloseFinishNoticeDialog = () => {
        setDialogFinishFormData({
            sentAt: null,
            sentBy: '',
        });
        setNoticeFinishTarget(null);
    };

    const sourceFilterTemplate = (
        options: ColumnFilterElementTemplateOptions
    ) => {
        return (
            <InputText
                value={options.value ?? ''}
                onChange={(e) =>
                    options.filterCallback(e.target.value, options.index)
                }
                placeholder='ex. dood'
                className='p-column-filter'
            />
        );
    };

    const sourceTypeFilterTemplate = (
        options: ColumnFilterElementTemplateOptions
    ) => {
        return (
            <InputText
                value={options.value ?? ''}
                onChange={(e) =>
                    options.filterCallback(e.target.value, options.index)
                }
                placeholder='ex. cyberlocker'
                className='p-column-filter'
            />
        );
    };

    const statusFilterTemplate = (
        options: ColumnFilterElementTemplateOptions
    ) => {
        return (
            <Dropdown
                value={options.value}
                options={[
                    { label: 'New', value: 'new' },
                    { label: 'Queued', value: 'queued' },
                    { label: 'Sent', value: 'sent' },
                    { label: 'Assigned', value: 'assigned' },
                    { label: 'Error', value: 'error' },
                ]}
                onChange={(e) => options.filterCallback(e.value, options.index)}
                placeholder='Select status'
                className='p-column-filter'
            />
        );
    };

    const renderStatus = (rowData: Ether.Nomia.INotice) => {
        const status = rowData.status;
        let severity: BadgeProps['severity'] = 'info';

        if (status === 'queued') {
            severity = 'warning';
        } else if (status === 'error') {
            severity = 'danger';
        } else if (status === 'assigned') {
            severity = 'success';
        }

        return <Badge value={status} severity={severity} />;
    };

    const renderIsTest = (rowData: Ether.Nomia.INotice) => {
        const isTest = rowData.is_test;

        return (
            <Badge
                value={isTest == null ? 'null' : isTest ? 'true' : 'false'}
                severity={
                    isTest ? 'success' : isTest === false ? 'danger' : undefined
                }
            />
        );
    };

    const sentAtFilterTemplate = (
        options: ColumnFilterElementTemplateOptions
    ) => {
        return (
            <input
                className='ether-datetime'
                type='date'
                value={options.value ?? ''}
                onChange={(e) =>
                    options.filterCallback(e.target.value, options.index)
                }
            />
        );
    };

    const renderActionButtons = (rowData: Ether.Nomia.INotice) => {
        return (
            <div className='action-buttons-wrap'>
                <Button
                    className='p-button-outlined tooltip-button'
                    label='Items'
                    onMouseEnter={() => setHoveredNotice(rowData)}
                    onClick={() => setHoveredNotice(rowData)}
                />
                {rowData.status === 'queued' && (
                    <Button
                        className='p-button-outlined'
                        label='Finish'
                        onClick={() => setNoticeFinishTarget(rowData)}
                    />
                )}
            </div>
        );
    };

    return (
        <>
            <ObjectDisplayModal
                displayData={selectedNotice}
                visible={!!selectedNotice}
                onHide={() => setSelectedNotice(null)}
            />
            <Dialog
                visible={!!noticeFinishTarget}
                onHide={handleCloseFinishNoticeDialog}
                header={`Finish ${noticeFinishTarget?._id}`}
                style={{ width: '60vw' }}
            >
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '8px',
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            gap: '8px',
                        }}
                    >
                        <Datepicker
                            required
                            type='datetime-local'
                            label='Sent at'
                            value={dialogFinishFormData.sentAt}
                            onChange={(value) =>
                                setDialogFinishFormData((old) => ({
                                    ...old,
                                    sentAt: value,
                                }))
                            }
                        />
                        <InputText
                            required
                            label='Sent by'
                            value={dialogFinishFormData.sentBy}
                            onChange={(e) =>
                                setDialogFinishFormData((old) => ({
                                    ...old,
                                    sentBy: e.target.value,
                                }))
                            }
                            wrapperStyle={{ width: '100%' }}
                        />
                    </div>
                    <Button
                        className='p-button-outlined'
                        label='Finish'
                        disabled={
                            dialogFinishFormData.sentBy === '' ||
                            !dialogFinishFormData.sentAt
                        }
                        onClick={() =>
                            finishNoticeMutation.mutate({
                                noticeId: noticeFinishTarget?._id as string,
                                data: {
                                    sent_at:
                                        dialogFinishFormData.sentAt as Date,
                                    sent_info: JSON.stringify({
                                        sent_by: dialogFinishFormData.sentBy,
                                        sent_manually: true,
                                    }) as string,
                                },
                            })
                        }
                    />
                </div>
            </Dialog>
            <Tooltip target='.tooltip-button' autoHide={false} position='left'>
                {''}
                {hoveredNotice && (
                    <div
                        style={{ display: 'flex', gap: '8px' }}
                        onMouseLeave={() => setHoveredNotice(null)}
                    >
                        <Link to={`${hoveredNotice._id}/noticeitems`}>
                            <Button
                                className='p-button-outlined'
                                label='List'
                            />
                        </Link>
                        <Button
                            className='p-button-outlined'
                            label='Copy'
                            onClick={() =>
                                copyNoticeItemsToClipboardMutation.mutate(
                                    hoveredNotice._id
                                )
                            }
                            disabled={
                                (hoveredNotice.noticeitem_count ?? 0) === 0 ||
                                exportNoticeItemsMutation.isLoading ||
                                copyNoticeItemsToClipboardMutation.isLoading
                            }
                            loading={
                                currentDownloadingNoticeId ===
                                    hoveredNotice._id &&
                                copyNoticeItemsToClipboardMutation.isLoading
                            }
                        />
                        <Button
                            className='p-button-outlined'
                            label='Download'
                            onClick={() =>
                                exportNoticeItemsMutation.mutate(
                                    hoveredNotice._id
                                )
                            }
                            disabled={
                                (hoveredNotice.noticeitem_count ?? 0) === 0 ||
                                exportNoticeItemsMutation.isLoading ||
                                copyNoticeItemsToClipboardMutation.isLoading
                            }
                            loading={
                                currentDownloadingNoticeId ===
                                    hoveredNotice._id &&
                                exportNoticeItemsMutation.isLoading
                            }
                        />
                    </div>
                )}
            </Tooltip>
            {exportNoticeItemsMutation.isLoading && (
                <ProgressBar
                    value={downloadProgress}
                    style={{ marginTop: '8px', marginBottom: '8px' }}
                />
            )}
            <DataTable
                lazy={isLazy}
                value={data}
                loading={loading}
                emptyMessage='No notices available'
                filters={filterOptions?.filters}
                onFilter={filterOptions?.onFilter}
                onSort={sortOptions?.onSort}
                sortField={sortOptions?.sortField}
                sortOrder={sortOptions?.sortOrder}
                removableSort
            >
                {OidColumn({
                    hideFilter: filterOptions?.hideFilters,
                    sortable: true,
                })}
                <Column
                    field='target.source'
                    header='Source'
                    filter={!filterOptions?.hideFilters}
                    filterElement={sourceFilterTemplate}
                    showAddButton={false}
                    showFilterOperator={false}
                />
                <Column
                    field='target.source_type'
                    header='Source Type'
                    filter={!filterOptions?.hideFilters}
                    filterElement={sourceTypeFilterTemplate}
                    showAddButton={false}
                    showFilterOperator={false}
                />
                <Column
                    header='Item Count / Max'
                    body={(rowData: Ether.Nomia.INotice) => {
                        return `${rowData.noticeitem_count ?? '0'} / ${
                            rowData.max_items ?? '-'
                        }`;
                    }}
                />
                <Column
                    field='status'
                    header='Status'
                    body={renderStatus}
                    filter={!filterOptions?.hideFilters}
                    filterElement={statusFilterTemplate}
                    showFilterMatchModes={false}
                    showFilterOperator={false}
                    showFilterMenuOptions={false}
                />
                <Column
                    field='sent_at'
                    header='Sent at'
                    body={(rowData) => {
                        return rowData['sent_at'] ? (
                            <DateBadge value={rowData['sent_at']} />
                        ) : (
                            <Badge value={'-'} />
                        );
                    }}
                    dataType='date'
                    filter={!filterOptions?.hideFilters}
                    filterElement={sentAtFilterTemplate}
                    sortable
                    showAddButton={false}
                    showFilterOperator={false}
                />
                {!hideTestColumn && (
                    <Column
                        field='is_test'
                        header='Is test?'
                        body={renderIsTest}
                    />
                )}
                <Column
                    field='details'
                    header='Details'
                    body={(rowData: Ether.Nomia.INotice) => (
                        <Button
                            icon='pi pi-search'
                            className='p-button-outlined'
                            onClick={() => setSelectedNotice(rowData)}
                        />
                    )}
                />
                <Column header='Actions' body={renderActionButtons} />
            </DataTable>
        </>
    );
};

export default NoticesDataTable;
