import { useMutation, useQuery } from '@tanstack/react-query';
import { DateBadge, InputTextArea } from 'components/ethercity-primereact';
import { Badge } from 'primereact/badge';
import { Button } from 'primereact/button';
import { useEffect, useState } from 'react';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { queueCounterNotice } from 'services/nomia/notice';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Dialog } from 'primereact/dialog';
import { useNoticeQuery } from '../../../ListNotices/subpages';
import { useToast } from 'hooks/useToast';

import { listNoticeItemsByNotice } from 'services/nomia/noticeitem';

const LargeObjectView: React.FC<{
    children?: React.ReactNode;
}> = ({ children }) => {
    const [expand, setExpand] = useState(false);
    const content = JSON.stringify(children, null, 4);

    return (
        <div>
            {expand && (
                <Button
                    style={{ fontSize: '14px' }}
                    label='Hide'
                    icon='pi pi-minus'
                    className='p-button-outlined'
                    onClick={() => setExpand(false)}
                />
            )}
            <pre
                style={{
                    fontSize: '12px',
                    lineHeight: '16px',
                    maxHeight: expand ? '100%' : '160px',
                    overflowY: expand ? 'auto' : 'hidden',
                    whiteSpace: 'pre-wrap',
                }}
            >
                {content}
            </pre>
            {content.split('\n').length > 10 && !expand && (
                <Button
                    style={{ fontSize: '14px' }}
                    label='Show all'
                    icon='pi pi-plus'
                    className='p-button-outlined'
                    onClick={() => setExpand(true)}
                />
            )}
        </div>
    );
};

const isUrl = (value: string) => {
    let url;
    try {
        url = new URL(value);
    } catch {
        return false;
    }
    return url.protocol === 'http:' || url.protocol === 'https:';
};

const displayReceivedData = (
    data: any,
    onImageClick: (img: string | null) => void
) => {
    if (data == null) return <h1>No received data to display</h1>;
    if (data.constructor === Object) {
        return (
            <div
                style={{
                    display: 'grid',
                    gridTemplateColumns: 'repeat(3, 1fr)',
                    gap: '16px',
                }}
            >
                {Object.keys(data).map((key) => (
                    <DataCell
                        key={key}
                        label={key}
                        value={data[key]}
                        onImageClick={onImageClick}
                    />
                ))}
            </div>
        );
    }
    return (
        <>
            <h2>Received data</h2>
            <p>{data.toString()}</p>
        </>
    );
};

const displayOfferPicutes = (
    data: any,
    onImageClick: (img: string | null) => void
) => {
    if (!data?.item_info?.pictures) return null;
    return (
        <div style={{ marginBottom: '8px' }}>
            <h2>Pictures</h2>
            <div
                style={{
                    display: 'grid',
                    gridTemplateColumns: 'repeat(4, 1fr)',
                    gap: '16px',
                }}
            >
                {data.item_info.pictures
                    .filter((value: any) => !!value.url)
                    .map((value: any, key: number) => (
                        <div
                            key={key}
                            style={{
                                backgroundColor: 'var(--very-darkish-blue)',
                                borderRadius: '8px',
                                padding: '8px',
                            }}
                        >
                            <ShowImageOnButton
                                url={value.url}
                                onImageClick={() => onImageClick(value.url)}
                                defaultToOpen={true}
                                hideButton={true}
                            />
                        </div>
                    ))}
            </div>
        </div>
    );
};

const ShowImageOnButton: React.FC<{
    url: string;
    onImageClick: () => void;
    defaultToOpen?: boolean;
    hideButton?: boolean;
}> = ({ url, onImageClick, defaultToOpen, hideButton }) => {
    const [showImage, setShowImage] = useState(defaultToOpen ?? false);

    if (!showImage) {
        return (
            <div
                style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}
            >
                {!hideButton && (
                    <Button
                        label='Show image'
                        className='p-button-outlined'
                        onClick={() => setShowImage(true)}
                    />
                )}
                <a href={url} target='_blank' rel='noopener noreferrer'>
                    {url}
                </a>
            </div>
        );
    } else
        return (
            <div
                style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}
            >
                {!hideButton && (
                    <Button
                        label='Hide image'
                        className='p-button-outlined'
                        onClick={() => setShowImage(false)}
                    />
                )}
                <img
                    style={{
                        maxWidth: '100%',
                        cursor: 'pointer',
                    }}
                    src={url}
                    alt='Evidence'
                    onClick={onImageClick}
                />
            </div>
        );
};

const DataCell: React.FC<{
    label: string;
    value: any;
    onImageClick(value: string): void;
}> = ({ label, value, onImageClick }) => {
    const [component, setComponent] = useState<JSX.Element>();

    const setLink = (link: string) => {
        setComponent(
            <a href={link} target='_blank' rel='noopener noreferrer'>
                {link}
            </a>
        );
    };

    useEffect(() => {
        if (value == null) setComponent(<Badge value='null' />);
        else if (
            value instanceof Date ||
            (typeof value !== 'number' && !isNaN(new Date(value).getTime()))
        ) {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            value = new Date(value);
            setComponent(<DateBadge value={value} />);
        } else if (typeof value === 'string' || typeof value === 'number') {
            if (typeof value === 'string' && isUrl(value)) {
                setLink(value);
                fetch(value)
                    .then((res) => res.blob())
                    .then((buff) => {
                        const url = new URL(value);
                        const isImage =
                            url.pathname.endsWith('.jpeg') ||
                            url.pathname.endsWith('.png') ||
                            url.pathname.endsWith('.jpg') ||
                            buff.type.startsWith('image/');
                        if (isImage)
                            setComponent(
                                <ShowImageOnButton
                                    url={value}
                                    onImageClick={() => onImageClick(value)}
                                />
                            );
                    });
            } else setComponent(<span>{value}</span>);
        } else if (value instanceof Object) {
            setComponent(<LargeObjectView>{value}</LargeObjectView>);
        } else {
            setComponent(<span>{value.toString()}</span>);
        }
    }, []);

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '8px',
                alignItems: 'flex-start',
                backgroundColor: 'var(--very-darkish-blue)',
                borderRadius: '8px',
                padding: '8px',
                minWidth: '0px',
                wordBreak: 'break-all',
                overflowWrap: 'break-word',
            }}
        >
            <label style={{ fontSize: '20px', fontWeight: 'bold' }}>
                {label}
            </label>
            {component}
        </div>
    );
};

const ReplyCounterNotice = () => {
    const toast = useToast();
    const [answerText, setAnswerText] = useState<string>('');
    const [selectedImage, setSelectedImage] = useState<string | null>(null);

    const navigate = useNavigate();

    const params = useParams<{
        noticeOid: string;
        projectOid: string;
    }>() as { noticeOid: string; projectOid: string };

    const noticeQuery = useNoticeQuery();

    const counterNoticesItemQuery = useQuery(
        ['list-notice-items', params.projectOid, params.noticeOid],
        () => listNoticeItemsByNotice(params.noticeOid, params.projectOid),
        {
            enabled: noticeQuery?.status === 'success',
        }
    );

    const mutateQueueCounterNotice = useMutation(
        async ({ approved, answer }: { approved: boolean; answer: string }) => {
            return queueCounterNotice(params.noticeOid, approved, answer);
        },
        {
            onSuccess: () => {
                navigate(`/projects/${params.projectOid}/counternotices`);
            },
            onError: (err: Error) => {
                toast?.show({
                    severity: 'error',
                    summary: err.message,
                });
            },
        }
    );

    const receivedData = noticeQuery?.data?.counter_notice?.data?.content;

    if (noticeQuery?.data?.counter_notice?.status !== 'received') {
        return (
            <Navigate to={`/projects/${params.projectOid}/counternotices`} />
        );
    }

    const handleAccept = (approved: boolean) => {
        confirmDialog({
            message: `Answer - ${answerText}`,
            header: `${approved ? 'Approve' : 'Reject'} this counter-notice?`,
            accept: () =>
                mutateQueueCounterNotice.mutate({
                    approved,
                    answer: answerText,
                }),
            reject: () => {},
        });
    };

    return (
        <div>
            <ConfirmDialog />
            <Dialog
                visible={!!selectedImage}
                onHide={() => setSelectedImage(null)}
                header='Image'
            >
                {selectedImage && <img src={selectedImage} alt='Evidence' />}
            </Dialog>
            <h2>
                Counter-Notice - {noticeQuery.data?.target.source} -{' '}
                {params.noticeOid}
            </h2>

            <h3>Items</h3>
            {counterNoticesItemQuery.status === 'loading'
                ? 'Loading...'
                : counterNoticesItemQuery.data?.map((item) => (
                      <div
                          key={item._id}
                          style={{
                              backgroundColor: 'var(--very-darkish-blue)',
                              padding: '8px',
                              borderRadius: '8px',
                              marginBottom: '8px',
                              display: 'flex',
                              flexDirection: 'column',
                              gap: '8px',
                              alignItems: 'flex-start',
                          }}
                      >
                          {item.onoff_data.first_on_id ? (
                              <Button
                                  label='View screenshot'
                                  className='p-button-outlined'
                                  onClick={() =>
                                      setSelectedImage(
                                          `${window.API_URL}/get-onoff-screenshot/${item.onoff_data.first_on_id}`
                                      )
                                  }
                              />
                          ) : (
                              <div>No screenshot</div>
                          )}
                          <a
                              target='_blank'
                              rel='noopener noreferrer'
                              href={item.value}
                          >
                              {item.value}
                          </a>
                      </div>
                  ))}

            <h3>Info</h3>
            {displayOfferPicutes(receivedData, setSelectedImage)}
            {displayReceivedData(receivedData, setSelectedImage)}
            <div style={{ marginTop: '8px' }}>
                <InputTextArea
                    value={answerText}
                    onChange={(e) => setAnswerText(e.target.value)}
                    label='Answer'
                    wrapperStyle={{ width: '100%', minHeight: '120px' }}
                    style={{ height: '100px' }}
                />
                <div style={{ display: 'flex', gap: '8px', marginTop: '8px' }}>
                    <Button
                        disabled={answerText === ''}
                        className='p-button-success'
                        label='Approve'
                        onClick={() => handleAccept(true)}
                    />
                    <Button
                        disabled={answerText === ''}
                        className='p-button-danger'
                        label='Reject'
                        onClick={() => handleAccept(false)}
                    />
                </div>
            </div>
        </div>
    );
};

export default ReplyCounterNotice;
