import CacheControl from 'controller/cache/cacheController';
import { createContext, useContext, useEffect, useState } from 'react';
import api from 'services/api';
import { handleUserDirectoryCallback } from 'services/ether/base-api';
import { authenticateUser } from 'services/nomia/me';

import { defaultPermissions, getUserPermissions } from 'services/utils';

type FetchStatus = 'loading' | 'error' | 'success' | 'unauthenticated';

interface IAuthData {
    token: string | null;
    user: {
        id: string;
        name: string;
    } | null;
}

interface IUseAuth extends IAuthData {
    status: FetchStatus;
    error: string | null;
    permissions?: NomiaApp.Permissions;
    signOut?(): void;
    getUDLoginHref: () => string;
}

const AuthContext = createContext<IUseAuth | null>(null);

const nullablePermissions: Partial<NomiaApp.Permissions> = {};
Object.keys(defaultPermissions).forEach((key) => {
    nullablePermissions[key as keyof NomiaApp.Permissions] = false;
});
const initPerms = nullablePermissions as NomiaApp.Permissions;

const udUri = window.EXTERNAL_URI_USERDIRECTORY;

const AuthProvider: React.FC<{
    children?: React.ReactNode;
}> = ({ children }) => {
    const [permissions, setPermissions] =
        useState<NomiaApp.Permissions>(initPerms);

    const [error, setError] = useState<string | null>(null);
    const [authStatus, setAuthStatus] = useState<FetchStatus>('loading');

    const [authData] = useState<IAuthData>(() => {
        const data = CacheControl.Auth.get();
        if (!data) {
            return {
                token: null,
                user: null,
            };
        }
        return data;
    });

    const getUDLoginHref = () => {
        const callback =
            new URLSearchParams(window.location.search).get('callback') ??
            window.location.href.replace('/login', '/');
        const parsedCallback = new URL(callback);
        parsedCallback.searchParams.delete('hash');

        const url = new URL(udUri);
        url.pathname = '/app-login/nomia-v2';
        url.searchParams.append('callback', parsedCallback.toString());

        return url.toString();
    };

    const handleError = (message: string) => {
        setError(message);
        setAuthStatus('error');
        CacheControl.Auth.delete();
        delete api.defaults.headers.common['access-token'];
    };

    const signOut = () => {
        CacheControl.Auth.delete();
        const callback = window.location.origin + '/login';

        const url = new URL(udUri);
        url.pathname = '/logout';
        url.searchParams.append('redirect', callback);

        window.location.href = url.toString();
    };

    useEffect(() => {
        if (!authData) return;
        const urlParams = new URLSearchParams(window.location.search);
        const hash = urlParams.get('hash');
        if (!hash) {
            if (!authData.token) return setAuthStatus('unauthenticated');

            authenticateUser(authData.token)
                .then(({ user, permissions }) => {
                    if (!user) throw new Error('Received empty user from API');

                    if (permissions || user.is_superadmin)
                        setPermissions(
                            getUserPermissions(permissions, user.is_superadmin)
                        );

                    if (authData.token)
                        api.defaults.headers.common['access-token'] =
                            authData.token;

                    return setAuthStatus('success');
                })
                .catch((error) => handleError(`Can't authenticate. ${error}`));

            return;
        }

        const formData = new FormData();
        formData.append('hash', hash);
        handleUserDirectoryCallback(hash)
            .then(({ username, _id, access_token }) => {
                if (access_token) {
                    const userData: IAuthData = {
                        token: access_token.token,
                        user: {
                            id: _id,
                            name: username,
                        },
                    };
                    CacheControl.Auth.save(userData);
                    setTimeout(() => {
                        const url = new URL(window.location.href);
                        url.searchParams.delete('hash');
                        window.location.href = url.toString();
                    }, 500);
                    return;
                } else {
                    handleError(
                        'No token received. Verify with an admin if you have a token for this application (nomia_v2) on the User Directory.'
                    );
                }
            })
            .catch(() => {
                handleError('Failed to validate hash on UserDirectory.');
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authData]);

    return (
        <AuthContext.Provider
            value={{
                ...authData,
                status: authStatus,
                error,
                permissions,
                signOut,
                getUDLoginHref,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};

const useAuth = () => {
    const context = useContext(AuthContext);
    if (!context)
        throw new Error(
            'AuthContext must be used inside a AuthContext.Provider'
        );
    return context;
};


export { AuthProvider, useAuth };
