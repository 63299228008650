import { Button } from 'primereact/button';
import { Link } from 'react-router-dom';
import { useProjectQuery } from 'pages/Main/subpages/Projects/subpages/ListProjects/subpages';

const Metrics = () => {
    const projectQuery = useProjectQuery();

    return (
        <div>
            <h2>Metrics - {projectQuery?.data?.name}</h2>
            <div style={{ display: 'flex', gap: '8px' }}>
                <Link to='summary'>
                    <Button className='p-button-outlined' label='Summary' />
                </Link>
                <Link to='notice'>
                    <Button className='p-button-outlined' label='Notices' />
                </Link>
                <Link to='noticeitem'>
                    <Button className='p-button-outlined' label='Items' />
                </Link>
            </div>
        </div>
    );
};

export default Metrics;
